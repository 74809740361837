import { Select } from 'antd';
const { Option } = Select;
export const menuType = [
  <Option key={0} value={0}>
    Delivery
  </Option>,
  <Option key={1} value={1}>
    Pickup
  </Option>,
  <Option key="Both" value="Both">
    Both Delivery and Pickup
  </Option>,
];

export const menuTypeOrchestra = [
  <Option key={2} value={2}>
    Grocery
  </Option>,
  <Option key={3} value={3}>
    Restaurant
  </Option>,
];
