import { Divider, Row, Select } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { receiveError, receiveOrckCatMap, delOrckCatMap } from '../../redux/actions';
import VTButton from '../VTButton';
import OrckCatMappingRow from '../OrckCatMappingRow';

const { Option } = Select;

class OrckCatMapping extends Component {
  count = 1;
  state = { data: [] };

  init = () => {
    const { orchestraCategoryMappings } = this.props.menu;
    if (!orchestraCategoryMappings || orchestraCategoryMappings.length === 0) {
      this.setState({
        data: [
          {
            key: this.count,
            orchestraCategory: null,
            vtCategory: null,
            orchestraLocId: '',
            description: '',
          },
        ],
      });
    } else {
      const arr = [];
      orchestraCategoryMappings.map(i => {
        ++this.count;
        const obj = {};
        obj.key = this.count;
        obj.orchestraCategory = i.orchestraCategory || null;
        obj.vtCategory = i.vtCategory || null;
        obj.orchestraLocId = i.orchestraLocId || '';
        obj.description = i.description || '';
        arr.push(obj);
        return i;
      });
      this.setState({ data: arr });
    }
  };

  checkDuplicate = (v, o, key, field) => {
    const { data } = this.state;
    const row = data.find(i => i.key === key);
    if ((field === 'oc' && !row.vtCategory) || (field === 'vc' && !row.orchestraCategory))
      return false;
    const strArr = [];
    const othersArr = data.filter(i => i.key !== key);
    othersArr.map(i => {
      const str = i.orchestraCategory + ',' + i.vtCategory;
      strArr.push(str);
      return i;
    });

    let ocStr = '',
      vcStr = '';
    if (field === 'oc') {
      ocStr = v;
      vcStr = row.vtCategory;
    } else {
      ocStr = row.orchestraCategory;
      vcStr = v;
    }
    const cStr = ocStr + ',' + vcStr;
    return strArr.indexOf(cStr) >= 0;
  };

  checkRow = key => {
    const { data } = this.state;
    const arr = data.filter(i => !i.orchestraCategory || !i.vtCategory);
    if (arr.length === 0) {
      this.props.receiveError('');
      const orckMappings = [];
      data.map(i => {
        const obj = {};
        obj.orchestraCategory = i.orchestraCategory;
        obj.vtCategory = i.vtCategory;
        obj.orchestraLocId = i.orchestraLocId.trim() || '';
        obj.description = i.description.trim() || '';
        orckMappings.push(obj);
        return i;
      });
      this.props.receiveOrckCatMap(orckMappings);
    }
  };

  handleOrchCatChange = (v, o, key) => {
    const { data } = this.state;
    const field = 'oc';
    const isDuplicate = this.checkDuplicate(v, o, key, field);
    if (isDuplicate) {
      const qRow = data.filter(i => i.key === key);
      qRow[0].orchestraCategory = null;
      this.setState({ data });
      this.props.receiveError("That's going to be a duplicate!");
      return;
    }
    if (!isDuplicate) {
      this.props.receiveError('');
    }
    const dataItem = data.filter(i => i.key === key);
    if (v && o) {
      dataItem[0].orchestraCategory = o.value;
    }
    this.setState({ data });
    this.checkRow(key);
  };

  handleVTCatChange = (v, o, key) => {
    const { data } = this.state;
    const field = 'vc';
    const isDuplicate = this.checkDuplicate(v, o, key, field);
    if (isDuplicate) {
      const qRow = data.filter(i => i.key === key);
      qRow[0].vtCategory = null;
      this.setState({ data });
      this.props.receiveError("That's going to be a duplicate!");
      return;
    }
    if (!isDuplicate) {
      this.props.receiveError('');
    }
    const dataItem = data.filter(i => i.key === key);
    if (v && o) {
      dataItem[0].vtCategory = o.value;
    }
    this.setState({ data });
    this.checkRow(key);
  };

  handleOrchLocationIDChange = (e, key) => {
    const { data } = this.state;
    const dataItem = data.filter(i => i.key === key);
    dataItem[0].orchestraLocId = e.target.value;
    this.setState({ data });
    this.checkRow(key);
  };

  HandleOrchCatMapDescChange = (e, key) => {
    const { data } = this.state;
    const dataItem = data.filter(i => i.key === key);
    dataItem[0].description = e.target.value;
    this.setState({ data });
    this.checkRow(key);
  };

  handleDelOrchMapping = item => {
    const { data } = this.state;
    const arr = data.filter(i => !i.orchestraCategory || !i.vtCategory);
    if (arr.length === 1 && arr[0].key === item.key) {
      this.props.receiveError('');
    }
    const newData = data.filter(i => i.key !== item.key);
    this.setState({ data: newData });
    this.props.delOrckCatMap(item);
  };

  handleAddOrchMapping = () => {
    ++this.count;
    const { data } = this.state;
    const arr = data.filter(i => !i.orchestraCategory || !i.vtCategory);
    if (arr.length) {
      this.props.receiveError('Please complete or delete the incomplete row(s)!');
      return;
    }
    const newRow = {
      key: this.count,
      orchestraCategory: null,
      vtCategory: null,
      orchestraLocId: '',
      description: '',
    };
    this.setState({ data: [...data, newRow] });
    this.props.receiveError('');
  };

  componentDidMount() {
    this.init();
  }

  render() {
    const { data } = this.state;
    const { menuGroups } = this.props.menu;
    const VTCats = menuGroups.map(i => (
      <Option key={i.groupCode} value={i.groupCode}>
        {i.description}
      </Option>
    ));

    return (
      <div>
        {data.map(i => (
          <OrckCatMappingRow
            key={i.key}
            mapping={i}
            VTCats={VTCats}
            onOrchCatChange={(v, o) => this.handleOrchCatChange(v, o, i.key)}
            onVTCatChange={(v, o) => this.handleVTCatChange(v, o, i.key)}
            onOrchLocationIDChange={e => this.handleOrchLocationIDChange(e, i.key)}
            onOrchCatMapDescChange={e => this.HandleOrchCatMapDescChange(e, i.key)}
            onOrchMapDelete={() => this.handleDelOrchMapping(i)}
          />
        ))}
        <Divider style={{ borderColor: '#fff', marginBottom: '0' }} />
        <Row justify="end">
          <VTButton
            text="Add a Mapping"
            style={{ width: 130, height: 35 }}
            onClick={this.handleAddOrchMapping}
          />
        </Row>
      </div>
    );
  }
}
export default connect(
  state => ({
    menu: state.menu,
    error: state.error,
  }),
  { receiveError, receiveOrckCatMap, delOrckCatMap },
)(OrckCatMapping);
