export const LOGIN_ERROR = 'login_error';
export const RECEIVE_USER = 'receive_user';
export const RESET_USER = 'reset_user';
export const RECEIVE_ERROR = 'receive_error';
export const MENU_NEWINIT = 'menu_newInit';
export const MENU_EDITINIT = 'menu_editInit';
export const MENU_RECEIVE_GROUP = 'menu_receive_group';
export const MENU_UPDATE_GROUP = 'menu_update_group';
export const MENU_DEL_GROUP = 'menu_delete_group';
export const MENU_DEL_ITEMS = 'menu_delete_items';
export const MENU_DEL_REMOVED_ITEMS = 'menu_delete_removed_items';
export const RECEIVE_STORE_HOURS = 'receive_store_hours';
export const RECEIVE_SPECIAL_HOURS = 'receive_special_hours';
export const DELETE_SPECIAL_HOURS = 'delete_special_hours';
export const RECEIVE_TAX_MAP = 'receive_tax_map';
export const RECEIVE_PMT_INFO = 'receive_pmt_info';
export const RECEIVE_PC_MAP = 'receive_pc_map';
export const DELETE_PC_MAP = 'delete_pc_map';
export const MENU_RECEIVE_ITEMS = 'menu_receive_items';
export const MENU_UPDATE_ITEMS = 'menu_update_items';
export const MENU_UPDATE_ITEM_SIZES = 'menu_update_item_sizes';
export const MENU_UPDATE_ITEM_MODS = 'menu_update_item_mods';
export const MENU_UPDATE_ITEM_MOD_ITEMS = 'menu_update_item_mod_items';
export const RECEIVE_MENU_DELIPARTY = 'receive_menu_deli_party';
export const RECEIVE_MENU_TYPE = 'receive_menu_type';
export const RECEIVE_MENU_CODE = 'receive_menu_code';
export const RECEIVE_MENU_NAME = 'receive_menu_name';
export const RECEIVE_MENU_DESC = 'receive_menu_desc';
export const RECEIVE_ORCK_CAT_MAP = 'receive_orck_cat_desc';
export const DELETE_ORCK_CAT_MAP = 'delete_orck_cat_desc';
export const RECEIVE_FLYT_UPLOAD_STORE_LIST = 'receive_flyt_upload_store_list';
export const RECEIVE_PARTNERS = 'receive_partners';
export const UPDATE_PARTNERS = 'update_partners';
export const ADD_PARTNER = 'add_partner';
export const RECEIVE_PARTNER_STORE = 'receive_partner_store';
export const UPDATE_PARTNER_STORES = 'update_partner_stores';
export const DELETE_PARTNER_STORE = 'delete_partner_store';