import React, { Component } from 'react';
import { Checkbox, DatePicker, Row, Col, Popconfirm, Typography } from 'antd';
import moment from 'moment';

import StoreHourSelector from '../StoreHourSelector';

export default class SpecialHoursRow extends Component {
  state = {
    closed: null,
    date: null,
    time: null,
  };

  init = () => {
    const { checked, date, time } = this.props;
    this.setState({ closed: checked, date, time });
  };

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps, prevState) {
    const { date, time } = this.props;
    if (prevProps.time !== time || prevProps.date !== date) {
      this.init();
    }
  }

  render() {
    const { closed, date, time } = this.state;
    const { onDateChange, onStoreHourChange, onCheckboxChange, onDateDelete, timeDisabled } =
      this.props;
    return (
      <Row gutter={16} align="middle" className="vt-row">
        <Col>
          <DatePicker
            allowClear={false}
            value={date === null ? null : moment(date)}
            onChange={(date, dateString) => onDateChange(date, dateString)}
          />
        </Col>
        <Col>
          <StoreHourSelector
            timeDisabled={timeDisabled}
            value={time}
            allowClear={false}
            onStoreHourChange={(time, timeString) => onStoreHourChange(time, timeString)}
          />
        </Col>
        <Col>
          <Checkbox
            checked={closed}
            onChange={e => {
              this.setState({ closed: e.target.checked });
              onCheckboxChange(e);
            }}
          />
          <span style={{ marginLeft: 5 }}>Closed</span>
        </Col>
        <Col>
          <Popconfirm title="Sure to delete?" onConfirm={() => onDateDelete()}>
            <Typography.Link>Delete</Typography.Link>
          </Popconfirm>
        </Col>
      </Row>
    );
  }
}
