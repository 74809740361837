import React, { Component } from 'react';
import { Table } from 'antd';

import { orckTaskCreated } from '../../util/date';

export default class OrckTaskLogsTable extends Component {
  columns = [
    {
      title: 'Execution Date',
      render: (_, record) => <span>{orckTaskCreated(record.executionTime)}</span>,
    },
    {
      title: 'Task ID',
      dataIndex: 'taskId',
    },
    {
      title: 'Log Level',
      dataIndex: 'logLevel',
    },
    {
      title: 'Log Message',
      dataIndex: 'message',
    },
  ];

  render() {
    const { logs } = this.props;
    let logsSorted = logs;
    logsSorted = logsSorted.sort((a, b) => new Date(b.executionTime) - new Date(a.executionTime));
    return (
      <Table
        style={{ whiteSpace: 'pre-wrap' }}
        size="middle"
        rowKey="id"
        columns={this.columns}
        dataSource={logsSorted}
        pagination={{ pageSize: 5 }}
      />
    );
  }
}
