import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Typography,
  Divider,
  Tabs,
  Modal,
  Select,
  Spin,
  message,
  Popconfirm,
} from 'antd';
import { receiveError, updatePartners, addDeliPartner, receivePartners } from '../../redux/actions';
import { reqUpdatePartner, reqDelPartner, reqPartners } from '../../api';
import VTButton from '../../components/VTButton';
import PartnerSetup from '../../components/PartnerSetup';
import Selection from '../../components/Selection';
import { deliPartners } from '../../config/partnerId';

const { TabPane } = Tabs;
const { Option } = Select;

class Partners extends Component {
  state = {
    activeTab: '',
    visible: false,
    modalDeliPartyValue: null,
    addTabpaneTitle: '',
    tabpaneSpinning: false,
  };

  init = () => {
    this.props.receiveError('');
    const { partners } = this.props || [];
    if (partners.length === 0) {
      this.props.history.replace('/console');
    }

    partners &&
      partners.length > 0 &&
      this.setState({ activeTab: partners[0].partnerType.toString() });
  };

  handleAddBtnClick = () => {
    this.setState({ visible: true });
  };

  handleSaveBtnClick = async key => {
    this.setState({ tabpaneSpinning: true });
    const { partners } = this.props;
    const partner = partners.find(i => i.partnerType === key);
    const re = await reqUpdatePartner(partner);
    if (re.status === 200) {
      this.setState({ tabpaneSpinning: false });
      message.success('Partner info successfully updated!');
    } else if (re === 'Server Error') {
      this.setState({ tabpaneSpinning: false });
    }
  };

  handleDeletePartner = async key => {
    this.setState({ tabpaneSpinning: true });
    const re = await reqDelPartner(key);
    if (re.status === 200) {
      const res = await reqPartners();
      if (res.status === 200 && res.data) {
        this.setState({ tabpaneSpinning: false });
        this.props.receivePartners(res.data);
        this.init();
      } else if (res === 'Server Error') {
        this.setState({ tabpaneSpinning: false });
      }
    } else if (re === 'Server Error') {
      this.setState({ tabpaneSpinning: false });
    }
  };

  handleTabClick = key => {
    this.setState({ activeTab: key });
  };

  handlePartnerInfoChange = data => {
    this.props.updatePartners(data);
  };

  handleModalDeliPartyChange = (v, o) => {
    this.setState({ modalDeliPartyValue: v, addTabpaneTitle: o.children });
  };

  handleModalCancel = () => {
    this.setState({ modalDeliPartyValue: null, visible: false });
  };

  handleModelOK = () => {
    const { modalDeliPartyValue, addTabpaneTitle } = this.state;
    if (modalDeliPartyValue === null || addTabpaneTitle === '') {
      this.setState({ visible: false });
      return;
    }
    const partner = {};
    partner.partnerType = modalDeliPartyValue * 1;
    partner.partnerName = addTabpaneTitle;
    this.props.addDeliPartner(partner);
    this.setState({ visible: false, modalDeliPartyValue: null, addTabpaneTitle: '' });
  };

  componentDidMount() {
    this.init();
  }

  render() {
    const { partners } = this.props || [];
    const { activeTab, visible, modalDeliPartyValue, tabpaneSpinning } = this.state;
    const arr = deliPartners.filter(i => partners.every(j => j.partnerType !== i.id)) || [];
    const options = arr.map(i => (
      <Option key={i.id} value={i.id}>
        {i.pName}
      </Option>
    ));

    return (
      <div>
        <Row style={{ backgroundColor: '#e4f2ff', padding: '20px' }} justify="end">
          <Col>
            <VTButton
              text="Add"
              style={{ width: 100, height: 35, marginBottom: 5 }}
              onClick={() => this.handleAddBtnClick()}
            />
          </Col>
        </Row>
        <Divider style={{ borderColor: '#fff' }} />
        <Spin spinning={tabpaneSpinning}>
          <Tabs
            size="large"
            type="card"
            tabPosition="left"
            tabBarStyle={{
              wordWrap: 'normal',
              fontWeight: '600',
              width: 180,
            }}
            tabBarGutter={8}
            activeKey={activeTab}
            onTabClick={key => this.handleTabClick(key)}
          >
            {partners.map(i => (
              <TabPane tab={i.partnerName} key={i.partnerType}>
                <Row
                  style={{ backgroundColor: '#e4f2ff', padding: '20px' }}
                  justify="space-between"
                  align="middle"
                >
                  <Popconfirm
                    title="Sure to delete?"
                    onConfirm={() => this.handleDeletePartner(i.partnerType)}
                  >
                    <Typography.Link>Delete</Typography.Link>
                  </Popconfirm>
                  <VTButton
                    text="Save"
                    style={{ width: 100, height: 35, marginBottom: 5 }}
                    onClick={() => this.handleSaveBtnClick(i.partnerType)}
                  />
                </Row>
                <Divider style={{ borderColor: '#fff', marginBottom: '0' }} />
                <PartnerSetup
                  partner={partners.find(j => j.partnerType.toString() === activeTab) || {}}
                  onPartnerChange={data => this.handlePartnerInfoChange(data)}
                />
              </TabPane>
            ))}
          </Tabs>
        </Spin>
        <Modal
          closable={false}
          maskClosable={false}
          visible={visible}
          title="Select Partner to Add"
          onCancel={this.handleModalCancel}
          onOk={this.handleModelOK}
        >
          <Row justify="center">
            <Selection
              width={220}
              data={options}
              value={modalDeliPartyValue}
              onChange={(value, option) => {
                this.handleModalDeliPartyChange(value, option);
              }}
            />
          </Row>
        </Modal>
      </div>
    );
  }
}
export default connect(state => ({ error: state.error, partners: state.partners }), {
  receiveError,
  updatePartners,
  addDeliPartner,
  receivePartners,
})(Partners);
