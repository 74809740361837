import React, { Component } from 'react';
import { connect } from 'react-redux';
import { receiveSpecialHours, receiveError, delSpecialHours } from '../../redux/actions';
import { Divider, Row } from 'antd';
import SpecialHoursRow from '../SpecialHoursRow';
import VTButton from '../VTButton';

class SpecialHours extends Component {
  count = 1;
  state = {
    data: [],
  };

  init = () => {
    const { specialHours } = this.props.menu;

    if (!specialHours || specialHours.length === 0) {
      this.setState({
        data: [
          {
            key: this.count,
            date: null,
            time: null,
            closed: true,
            timeDisabled: true,
          },
        ],
      });
    } else {
      const arr = [];
      specialHours.map(i => {
        ++this.count;
        const obj = {};
        obj.key = this.count;
        obj.date = i.date;
        obj.time = [];
        obj.time[0] = i.startTime;
        obj.time[1] = i.endTime;
        obj.closed = i.closed;
        obj.timeDisabled = false;
        arr.push(obj);
        return i;
      });
      this.setState({ data: arr });
    }
  };

  checkRow = key => {
    const { data } = this.state;
    const arr = data.filter(i => !i.date || (i.date && !i.time && !i.closed));
    if (arr.length > 0) {
      this.props.receiveError('Please complete or delete the incomplete row(s)!');
      return;
    }
    if (arr.length === 0) {
      this.props.receiveError('');
      const sHours = [];
      data.map(i => {
        const obj = {};
        obj.closed = i.closed;
        obj.date = i.date;
        if (i.time === null) {
          obj.startTime = '';
          obj.endTime = '';
        } else {
          obj.startTime = i.time[0];
          obj.endTime = i.time[1];
        }
        sHours.push(obj);
        return i;
      });
      this.props.receiveSpecialHours(sHours);
    }
  };

  handleDateChange = (date, dateString, key) => {
    const { data } = this.state;
    const arr = data.filter(i => i.date === dateString);
    if (arr.length) {
      this.props.receiveError("Let's pick a different date!");
      return;
    }
    const dateItem = data.filter(i => i.key === key);
    if (date && dateString) {
      dateItem[0].date = dateString;
      dateItem[0].timeDisabled = false;
    }
    this.setState({ data });
    this.checkRow(key);
  };

  handleSpecialHoursChange = (time, timeString, key) => {
    const { data } = this.state;
    const dateItem = data.filter(i => i.key === key);
    if (time && timeString) {
      dateItem[0].time = timeString;
      dateItem[0].closed = false;
    } else {
      dateItem[0].time = null;
      dateItem[0].closed = true;
    }
    this.setState({ data });
    this.checkRow(key);
  };

  handleCheckboxChange = (e, key) => {
    const { data } = this.state;
    const dateItem = data.filter(i => i.key === key);
    if (e.target.checked === true) {
      dateItem[0].time = null;
      dateItem[0].closed = true;
    } else {
      dateItem[0].closed = false;
    }
    this.setState({ data });
    this.checkRow(key);
  };

  handleAddDate = () => {
    ++this.count;
    const { data } = this.state;
    const arr = data.filter(i => !i.date || (i.date && !i.time && !i.closed));
    if (arr.length) {
      this.props.receiveError('Please complete or delete the incomplete row(s)!');
      return;
    }
    const newDate = {
      key: this.count,
      date: null,
      time: null,
      closed: true,
      timeDisabled: true,
    };
    this.setState({ data: [...data, newDate] });
    this.props.receiveError('');
  };

  handleDeleteDate = item => {
    const { data } = this.state;
    const arr = data.filter(i => !i.date || (i.date && !i.time && !i.closed));
    if (arr.length === 1 && arr[0].key === item.key) {
      this.props.receiveError('');
    }
    const newData = data.filter(i => i.key !== item.key);
    this.setState({ data: newData });
    this.props.delSpecialHours(item);
  };

  componentDidMount() {
    this.init();
  }

  render() {
    const { data } = this.state;
    return (
      <>
        <>
          {data.map(i => (
            <SpecialHoursRow
              key={i.key}
              date={i.date}
              time={i.time}
              checked={i.closed}
              timeDisabled={i.timeDisabled}
              onDateChange={(date, dateString) => this.handleDateChange(date, dateString, i.key)}
              onStoreHourChange={(time, timeString) =>
                this.handleSpecialHoursChange(time, timeString, i.key)
              }
              onCheckboxChange={e => this.handleCheckboxChange(e, i.key)}
              onDateDelete={() => this.handleDeleteDate(i)}
            />
          ))}
        </>
        <Divider style={{ borderColor: '#fff', marginBottom: '0' }} />
        <Row justify="end">
          <VTButton
            text="Add a Date"
            style={{ width: 100, height: 35 }}
            onClick={this.handleAddDate}
          />
        </Row>
      </>
    );
  }
}
export default connect(
  state => ({
    menu: state.menu,
    error: state.error,
  }),
  { receiveSpecialHours, receiveError, delSpecialHours },
)(SpecialHours);
