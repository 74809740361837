const monthlist = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export function headerDate() {
  const today = new Date();
  let year = today.getFullYear();
  let day = today.getDay();
  let daylist = ['Sunday', 'Monday', 'Tuesday', 'Wednesday ', 'Thursday', 'Friday', 'Saturday'];
  let month = today.getMonth();
  let date = today.getDate();
  return daylist[day] + ' ' + monthlist[month] + ' ' + date + ', ' + year;
}

export function lastUploadedDateTime(time) {
  if (!time) {
    return 'Not Available';
  }
  const str = time.split(' ').slice(0, 2).join('T');
  const date = new Date(str);
  if (date !== 'Invalid Date') {
    // let month = date.getMonth();
    // let min = date.getMinutes();
    // min = min < 10 ? '0' + min : min;
    // let sec = date.getSeconds();
    // sec = sec < 10 ? '0' + sec : sec;
    // const result =
    //   date.getHours() +
    //   ':' +
    //   min +
    //   ':' +
    //   sec +
    //   ', ' +
    //   monthlist[month] +
    //   ' ' +
    //   date.getDate() +
    //   ' ' +
    //   date.getFullYear();
    return date.toLocaleString();
  } else {
    return 'Not Available';
  }
}

export function orckTaskCreated(time){
  if (!time) {
    return 'Falied to retrieve date';
  }
  const date = new Date(time);
  return date.toLocaleString()
}
