import React, { Component } from 'react';
import { Row, Col, Popconfirm, Typography } from 'antd';
import { SwapOutlined } from '@ant-design/icons';
import Selection from '../Selection';

export default class ProfitCenterMappingRow extends Component {
  state = {
    mapping: null,
  };

  init = () => {
    const { mapping } = this.props;
    this.setState({ mapping });
  };

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps, prevState) {
    const { mapping } = this.props;
    if (prevState.mapping !== mapping) {
      this.init();
    }
  }

  render() {
    const { mapping } = this.state;
    const { onOrderTypeChange, onPCChange, onMappingDelete, orderTypeOptions, pcOptions, loading } =
      this.props;
    return (
      <Row gutter={16} align="middle" className="vt-row">
        <Col>
          <Selection
            toolTip="Order Type"
            width={220}
            data={orderTypeOptions}
            placeholder="Select Order Type"
            value={mapping === null ? null : mapping.orderType}
            onChange={(value, option) => onOrderTypeChange(value, option)}
          />
        </Col>
        <Col>
          <SwapOutlined />
        </Col>
        <Col>
          <Selection
            toolTip="Profit Center"
            width={220}
            data={pcOptions}
            loading={loading}
            placeholder="Select Profit Center"
            value={mapping === null ? null : mapping.profitCentreCode}
            onChange={(value, option) => onPCChange(value, option)}
          />
        </Col>
        <Col>
          <div>
            <Popconfirm title="Sure to delete?" onConfirm={() => onMappingDelete()}>
              <Typography.Link>Delete</Typography.Link>
            </Popconfirm>
          </div>
        </Col>
      </Row>
    );
  }
}
