import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Spin, Row, Col, message, Divider } from 'antd';
import OrckTasksTable from '../OrckTasksTable';
import OrckTaskLogsTable from '../OrckTaskLogsTable';
import { reqOrckTasks, reqOrckTaskLogs } from '../../api';
import storageUtil from '../../util/storeUtil';

class OrckTasks extends Component {
  state = { spinning: false, tasks: [], selectedTaskId: '', logs: [], refreshDisabled: false };

  init = async () => {
    const { menu } = this.props;
    this.setState({ selectedTaskId: '', logs: [] });
    if (!menu.id) {
      message.error("This menu hasn't been saved!", 2);
      return;
    }
    if (!menu.thirdPartyId) {
      message.error('This menu has no pending tasks!', 2);
      return;
    }
    this.setState({ spinning: true, refreshDisabled: true });
    const req = {};
    req.EnterpriseId = storageUtil.getEnt();
    req.StoreNumber = storageUtil.getStore();
    req.taskId = menu.thirdPartyId;
    const re = await reqOrckTasks(req);
    if (re.status === 200 && re.data) {
      this.setState({ tasks: re.data, spinning: false, refreshDisabled: false });
    } else if (re.status === 204) {
      this.setState({ tasks: [], spinning: false, refreshDisabled: false });
    } else if (re === 'Server Error') {
      this.setState({ spinning: false, refreshDisabled: false });
    }
  };

  handleTaskClick = async taskId => {
    this.setState({ selectedTaskId: taskId, spinning: true });
    const req = {};
    req.EnterpriseId = storageUtil.getEnt();
    req.StoreNumber = storageUtil.getStore();
    const re = await reqOrckTaskLogs(taskId, req);
    if (re.status === 200 && re.data) {
      this.setState({ logs: re.data, spinning: false });
    } else if (re === 'Server Error') {
      this.setState({ spinning: false });
    }
  };

  handleRefreshTab = () => {
    this.init();
  };

  componentDidMount() {
    this.init();
  }

  render() {
    const { spinning, tasks, logs, refreshDisabled } = this.state;
    return (
      <Spin spinning={spinning}>
        <Row style={{ maxHeight: 300, overflow: 'auto' }}>
          <Col span={24}>
            <OrckTasksTable
              tasks={tasks}
              onTaskClick={taskId => this.handleTaskClick(taskId)}
              onRefreshClick={this.handleRefreshTab}
              refreshDisabled={refreshDisabled}
            />
          </Col>
        </Row>
        <Divider style={{ borderColor: '#fff' }} />
        <Row>
          <Col span={24}>
            <OrckTaskLogsTable logs={logs} />
          </Col>
        </Row>
      </Spin>
    );
  }
}
export default connect(state => ({ menu: state.menu }), {})(OrckTasks);
