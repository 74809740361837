import { Select } from 'antd';
const { Option } = Select;

export const OrckCats = [
  <Option key='Grocery' value='Grocery'>
    Grocery
  </Option>,
  <Option key='Restaurant' value='Restaurant'>
    Restaurant
  </Option>,
  <Option key='LevelOneCategory' value='LevelOneCategory'>
    Level One Category
  </Option>,
  <Option key='RestaurantFeaturedCategory' value='RestaurantFeaturedCategory'>
    Restaurant Featured Category
  </Option>,
]