import React, { Component } from 'react';
import { Table, Button, Row, Tooltip, Typography } from 'antd';
import { RedoOutlined } from '@ant-design/icons';
import { orckTaskStatus } from '../../config/orckTaskStatus';
import { orckTaskCreated } from '../../util/date';

export default class OrckTasksTable extends Component {
  columns = [
    {
      title: 'Created',
      render: (_, record) => <span>{orckTaskCreated(record.created)}</span>,
    },
    {
      title: 'Task Id',
      dataIndex: 'taskId',
    },
    {
      title: 'Task Name',
      dataIndex: 'name',
    },
    {
      title: 'Status',
      render: (_, record) => {
        const statusObj = orckTaskStatus.find(i => i.statusCode === record.status);
        const { status } = statusObj;
        return <span>{status}</span>;
      },
    },
    {
      title: () => (
        <Row justify="space-between" align="middle">
          <span>Action</span>
          <Tooltip title="Refresh">
            <Typography.Link
              type="primary"
              onClick={this.handleRefreshTab}
              disabled={this.props.refreshDisabled}
            >
              <RedoOutlined style={{ fontSize: 21, marginRight: 5 }} />
            </Typography.Link>
          </Tooltip>
        </Row>
      ),
      render: (_, record) => (
        <Row justify="center">
          <Button
            key={record.taskId}
            type="primary"
            size="small"
            style={{ width: 70, height: 30 }}
            onClick={() => this.handleTaskClick(record.taskId)}
          >
            Get Log
          </Button>
        </Row>
      ),
    },
  ];

  handleTaskClick = taskId => {
    this.props.onTaskClick(taskId);
  };

  handleRefreshTab = () => {
    this.props.onRefreshClick();
  };

  render() {
    const { tasks } = this.props || [];

    return (
      <Table
        size="middle"
        rowKey="taskId"
        columns={this.columns}
        dataSource={tasks}
        pagination={false}
      />
    );
  }
}
