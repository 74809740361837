import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { updateMenuItems } from '../../redux/actions';
import EditableTable from '../EditableTable';

class MenuItemsTable extends Component {
  state = {
    panelOrGroup: null,
    pOrGValue: null,
    dataSource: [],
    selItemCodes: [],
  };

  init = () => {
    const { panelOrGroup, pOrGValue, isMPanel, itemList } = this.props;
    const { items: menuItems } = this.props.menu;
    const itemSource = panelOrGroup === 'Panel' && isMPanel ? 2 : panelOrGroup === 'Panel' ? 0 : 1;
    const arr = menuItems.filter(i => i.catCode === pOrGValue && i.itemSource === itemSource);
    let selItemCodes = [];
    let dataSource = JSON.parse(JSON.stringify(arr));
    dataSource = dataSource.map(i => {
      if (i.selected) {
        selItemCodes.push(i.itemCode);
      }
      if (i.price) {
        i.price = parseFloat(i.price.toString() === '' ? 0 : i.price.toString()).toFixed(2);
      } else {
        i.price = '0.00';
      }
      i.cName = itemList.find(j => j.itemCode === i.itemCode).description || '';
      if (i.itemName === '') {
        i.itemName = itemList.find(j => j.itemCode === i.itemCode).description || '';
      }
      return i;
    });

    dataSource = dataSource.sort((a, b) => a.cName.localeCompare(b.cName));
    this.setState({ panelOrGroup, pOrGValue, dataSource, selItemCodes });
  };

  handleSelectionChange = (keys, rows) => {
    const { pOrGValue } = this.props;
    const { items: menuItems } = this.props.menu;
    const groupItems = menuItems.filter(i => i.catCode === pOrGValue);
    let arr = JSON.parse(JSON.stringify(groupItems));
    arr.map(i => {
      if (keys.indexOf(i.itemCode) >= 0) {
        i.selected = true;
      } else {
        i.selected = false;
      }
      return i;
    });
    this.setState({ selItemCodes: keys });
    this.props.updateMenuItems(arr);
  };

  getUpdatedItemTableData = data => {
    const { selItemCodes } = this.state;
    data.map(i => {
      if (selItemCodes.indexOf(i.itemCode) >= 0) {
        i.selected = true;
      } else {
        i.selected = false;
      }
      return i;
    });
    this.props.updateMenuItems(data);
  };

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps, prevState) {
    const { panelOrGroup, pOrGValue } = this.props;
    const { menu } = this.props;
    if (
      prevState.panelOrGroup !== panelOrGroup ||
      prevState.pOrGValue !== pOrGValue ||
      prevProps.menu.items !== menu.items
    ) {
      this.init();
    }
  }

  render() {
    const { panelOrGroup, selItemCodes, dataSource } = this.state;
    const { onSizeClick, onModsClick, itemList } = this.props;
    const columns = [
      {
        title: 'Item Code',
        dataIndex: 'itemCode',
        render: (text, record) => (
          <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>{text}</div>
        ),
      },
      {
        title: 'VisualTouch Item Name',
        dataIndex: 'cName',
        sorter: (a, b) => a.cName.localeCompare(b.cName),
        // render: (text, record) => {
        //   const item = itemList.find(i => i.itemCode === record.itemCode) || {};
        //   return (
        //     <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
        //       {item.description || ''}
        //     </div>
        //   );
        // },
      },
      {
        title: () => (
          <Row justify="space-between" align="middle">
            <span>Online Item Name</span>
            <EditOutlined style={{ color: '#a5a5a5', marginRight: 2 }} />
          </Row>
        ),
        // render: (text, record) => {
        //   let itemName = record.itemName;
        //   if (itemName === '') {
        //     //const item = itemList.find(i => i.itemCode === record.itemCode) || {};
        //     itemName = record.cName || '';
        //   }
        //   return <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>{itemName}</div>;
        // },
        dataIndex: 'itemName',
        editable: true,
      },
      {
        title: () => (
          <Row justify="space-between" align="middle">
            <span>Short Description</span>
            <EditOutlined style={{ color: '#a5a5a5', marginRight: 2 }} />
          </Row>
        ),
        dataIndex: 'shortDescription',
        editable: true,
      },
      {
        title: () => (
          <Row justify="space-between" align="middle">
            <span>Description</span>
            <EditOutlined style={{ color: '#a5a5a5', marginRight: 2 }} />
          </Row>
        ),
        dataIndex: 'description',
        editable: true,
      },
      {
        title: () => (
          <Row justify="space-between" align="middle">
            <span>Price</span>
            <EditOutlined style={{ color: '#a5a5a5', marginRight: 2 }} />
          </Row>
        ),
        dataIndex: 'price',
        editable: true,
      },
      {
        title: 'Sizes/Mods',
        render: item => (
          <>
            {item.itemSizes.length > 0 ? (
              <Button
                key={1}
                type="primary"
                size="small"
                style={{ width: 50, marginRight: 5, marginBottom: 5 }}
                onClick={() => onSizeClick(item)}
              >
                Sizes
              </Button>
            ) : (
              <></>
            )}
            {item.modifiers.length > 0 ? (
              <Button
                key={2}
                type="primary"
                size="small"
                style={{ width: 50 }}
                onClick={() => onModsClick(item)}
              >
                Mods
              </Button>
            ) : (
              <></>
            )}
          </>
        ),
      },
    ];

    return (
      <EditableTable
        size="small"
        rowKey="itemCode"
        tableColumns={columns}
        tableDataSource={dataSource}
        rowSelection={{
          type: 'Checkbox',
          selectedRowKeys: selItemCodes,
          onChange: (keys, rows) => this.handleSelectionChange(keys, rows),
          getCheckboxProps: record => ({
            disabled: panelOrGroup === 'Group',
          }),
        }}
        pagination={false}
        pOrG={panelOrGroup}
        itemSize={false}
        itemMods={false}
        getUpdatedData={updatedData => this.getUpdatedItemTableData(updatedData)}
      />
    );
  }
}
export default connect(
  state => ({
    menu: state.menu,
  }),
  { updateMenuItems },
)(MenuItemsTable);
