import React, { Component } from 'react';
import { Modal, Row, Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import EditableTable from '../EditableTable';
import { connect } from 'react-redux';
import { updateMenuItemSizes } from '../../redux/actions';

class MenuItemSizeModal extends Component {
  state = {
    visible: null,
    sizes: [],
    itemCode: '',
    itemName: '',
  };

  init = () => {
    const { visible, item } = this.props;
    let sizes = [],
      itemName = '',
      itemCode = '';
    if (item && item.itemSizes) {
      sizes = JSON.parse(JSON.stringify(item.itemSizes));
      sizes.map(i => {
        if (i.price) {
          i.price = parseFloat(i.price.toString() === '' ? 0 : i.price.toString()).toFixed(2);
        } else {
          i.price = '0.00';
        }
        return i;
      });
      itemCode = item.itemCode;
      itemName = item.itemName;
    }
    this.setState({ visible, sizes, itemCode, itemName });
  };

  getUpdatedSizeData = data => {
    const { item } = this.props;
    data.map(i => {
      i.price = parseFloat(i.price.toString() === '' ? 0 : i.price.toString()).toFixed(2);
      return i;
    });
    const obj = {};
    obj.item = item;
    obj.sizes = data;
    this.props.updateMenuItemSizes(obj);
  };

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps, prevState) {
    const { visible, item } = this.props;
    if (prevState.visible !== visible || prevProps.item !== item) {
      this.init();
    }
  }

  render() {
    const { visible, sizes, itemCode, itemName } = this.state;
    const { pOrG, onSizeCancel, closable } = this.props;

    const columns = [
      {
        title: 'Size',
        dataIndex: 'code',
      },
      {
        title: 'Name',
        dataIndex: 'name',
      },
      {
        title: () => (
          <Row justify="space-between" align="middle">
            <span>Price </span>
            <EditOutlined style={{ color: '#a5a5a5', marginRight: 2 }} />
          </Row>
        ),
        dataIndex: 'price',
        editable: true,
      },
    ];

    return (
      <Modal
        closable={closable}
        maskClosable={false}
        visible={visible}
        title={
          <div>
            {itemCode}
            &nbsp;&nbsp;&nbsp;
            {itemName}
          </div>
        }
        footer={[
          <Button key="0" type="primary" onClick={onSizeCancel}>
            Close
          </Button>,
        ]}
      >
        {/* props: tableDataSource, tableColumns, size, rowKey, rowSelection, pagination */}
        <EditableTable
          size="small"
          rowKey="code"
          tableColumns={columns}
          tableDataSource={sizes}
          pagination={false}
          pOrG={pOrG}
          itemSize={true}
          itemMods={false}
          getUpdatedData={updatedData => this.getUpdatedSizeData(updatedData)}
        />
      </Modal>
    );
  }
}
export default connect(
  state => ({
    menu: state.menu,
  }),
  { updateMenuItemSizes },
)(MenuItemSizeModal);
