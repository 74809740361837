import React, { Component } from 'react';
import './index.css';

export default class Error extends Component {
  render() {
    const { errormsg } = this.props;
    return (
      <div {...this.props} className="err">
        {errormsg}
      </div>
    );
  }
}
