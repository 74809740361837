import React, { Component } from 'react';
import { TimePicker } from 'antd';
import { CloseCircleFilled } from '@ant-design/icons';
import moment from 'moment';

export default class StoreHourSelector extends Component {
  state = {
    timeObj: null,
  };

  init = () => {
    const { value } = this.props;
    let arr = [];
    if (value && value[0] && value[1]) {
      arr[0] = moment(value[0], 'HH:mm');
      arr[1] = moment(value[1], 'HH:mm');
    } else {
      arr = [];
    }
    this.setState({ timeObj: arr });
  };

  handleChange = (time, timeString) => {
    this.setState({ timeObj: time });
  };

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.value !== this.props.value) {
      this.init();
    }
  }

  render() {
    const { timeObj } = this.state;
    const { onStoreHourChange, allowClear, style, timeDisabled } = this.props;

    return (
      <TimePicker.RangePicker
        style={style}
        allowClear={allowClear}
        disabled={timeDisabled}
        value={timeObj}
        clearIcon={<CloseCircleFilled />}
        format="HH:mm"
        onChange={(time, timeString) => {
          onStoreHourChange(time, timeString);
          this.handleChange(time, timeString);
        }}
      />
    );
  }
}
