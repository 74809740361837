import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table } from 'antd';
import { receiveError, receiveStoreHours } from '../../redux/actions';
import StoreHourSelector from '../StoreHourSelector';

class StoreHours extends Component {
  state = { data: [] };
  columns = [
    {
      title: 'Day',
      dataIndex: 'day',
      key: 'day',
    },
    {
      title: 'Hours',
      dataIndex: 'hours',
      key: 'hours',
      render: (text, record) => (
        <StoreHourSelector
          key={record.dayIndex}
          value={[record['startTime'], record['endTime']]}
          allowClear={true}
          onStoreHourChange={(time, timeString) =>
            this.handleStoreHourChange(time, timeString, record.dayIndex)
          }
        />
      ),
    },
  ];

  days = [
    {
      dayIndex: '0',
      day: 'Sunday',
      startTime: '',
      endTime: '',
    },
    {
      dayIndex: '1',
      day: 'Monday',
      startTime: '',
      endTime: '',
    },
    {
      dayIndex: '2',
      day: 'Tuesday',
      startTime: '',
      endTime: '',
    },
    {
      dayIndex: '3',
      day: 'Wednesday',
      startTime: '',
      endTime: '',
    },
    {
      dayIndex: '4',
      day: 'Thursday',
      startTime: '',
      endTime: '',
    },
    {
      dayIndex: '5',
      day: 'Friday',
      startTime: '',
      endTime: '',
    },
    {
      dayIndex: '6',
      day: 'Saturday',
      startTime: '',
      endTime: '',
    },
  ];

  init = () => {
    const { openHours } = this.props.menu;
    if (openHours.length === 0) {
      this.setState({ data: this.days });
    } else {
      let arr = [];
      Object.assign(arr, openHours);
      const aArr = this.days.map(i =>
        arr.map(j => {
          if (i.dayIndex === j.dayIndex) {
            i.startTime = j.startTime;
            i.endTime = j.endTime;
          }
          return i;
        }),
      );
      const data = aArr.map(i => {
        return i[0];
      });
      this.setState({ data });
    }
  };

  checkRow = index => {
    const { data } = this.state;
    const arr = data.filter(
      i => i.startTime !== '' && i.endTime !== '' && i.startTime === i.endTime,
    );
    if (arr.length === 0) {
      this.props.receiveError('');
    }
  };

  handleStoreHourChange = (time, timeString, index) => {
    const { data } = this.state;
    const day = data.find(i => i.dayIndex === index);
    if (timeString[0] !== '' && timeString[1] !== '' && timeString[0] === timeString[1]) {
      this.props.receiveError('Two time values of the same row cannot be the same!');
    }
    day.startTime = timeString[0];
    day.endTime = timeString[1];
    this.setState({ data });
    const storeHours = {};
    storeHours.dayIndex = index;
    storeHours.startTime = timeString[0];
    storeHours.endTime = timeString[1];
    this.props.receiveStoreHours(storeHours);
    this.checkRow(index);
  };

  componentDidMount() {
    this.init();
  }

  render() {
    const { data } = this.state;
    return (
      <Table
        size="middle"
        rowKey="dayIndex"
        columns={this.columns}
        dataSource={data}
        pagination={false}
      />
    );
  }
}
export default connect(
  state => ({
    error: state.error,
    menu: state.menu,
  }),
  { receiveError, receiveStoreHours },
)(StoreHours);
