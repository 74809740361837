import React, { Component } from 'react';
import { Row, Col, Select, Divider, Spin, Typography } from 'antd';
import { connect } from 'react-redux';
import { receiveError, initEditMenu, initNewMenu, receivePartners } from '../../redux/actions';
import { reqStores, reqMenuList, reqPartners } from '../../api';
import Selection from '../../components/Selection';
import VTButton from '../../components/VTButton';
import storageUtil from '../../util/storeUtil';
import { nanoid } from '@reduxjs/toolkit';

const { Option } = Select;

class Console extends Component {
  state = {
    userType: null,
    ent: null,
    ents: [],
    store: null,
    stores: [],
    storeListLoading: false,
    menuList: [],
    menu: {},
    menuSelected: null,
    btnText: 'New Menu',
    storeDisabled: true,
    menuDisabled: true,
    menuListLoading: false,
    spinning: false,
  };

  init = () => {
    this.props.receiveError('');
    const { user } = this.props;
    const { userType } = user;
    this.setState({ userType });
    if (user && user.clouds) {
      const { clouds } = user;
      let arr = JSON.parse(JSON.stringify(clouds));
      arr = arr.sort((a, b) => a.description.localeCompare(b.description));
      this.setState({ ents: arr });
      if (arr.length === 1) {
        this.setState({ ent: arr[0].enterpriseId });
        this.handleEntChange(arr[0].enterpriseId);
      }
    }
    if (storageUtil.getEnt() !== '') {
      this.handleEntChange(storageUtil.getEnt());
    }
    if (storageUtil.getStore() !== '') {
      this.handleStoreChange(storageUtil.getStore());
    }
    this.setState({ ent: storageUtil.getEnt() || null, store: storageUtil.getStore() || null });
  };

  handleEntChange = async v => {
    const { storeDisabled, store } = this.state;
    const { userType, clouds } = this.props.user;

    if (v) {
      storageUtil.saveEnt(v);
      if (this.props.error !== '') {
        this.props.receiveError('');
      }
      if (store !== null) {
        this.setState({ store: null, menuSelected: null });
      }
      this.setState({ ent: v, storeListLoading: true });
      if (userType === 9) {
        const re = await reqStores(v);
        if (re === 'Ent Error' || re === 'No Cloud Data') {
          this.setState({ storeListLoading: false });
          this.handleSelectionClear();
        }
        if (re.status === 200 && re.data.length >= 0) {
          if (re.data.length === 1) {
            this.setState({ store: re.data[0].storeNumber });
            this.handleStoreChange(re.data[0].storeNumber);
          }
          this.setState({ stores: re.data, storeListLoading: false });
        } else {
          this.setState({ storeListLoading: false });
        }
      } else {
        const { stores } = clouds.find(i => i.enterpriseId === v);
        if (stores.length === 1) {
          this.setState({ store: stores[0].storeNumber });
          this.handleStoreChange(stores[0].storeNumber);
        }
        this.setState({ stores, storeListLoading: false });
      }
    } else {
      if (!storeDisabled) {
        this.setState({ storeDisabled: true });
      }
    }
    if (storeDisabled) {
      this.setState({ storeDisabled: false });
    }
  };

  handleStoreChange = async v => {
    const { menuDisabled, menuSelected } = this.state;
    if (v) {
      if (this.props.error !== '') {
        this.props.receiveError('');
      }
      if (menuSelected !== null) {
        this.setState({ menuSelected: null });
      }
      storageUtil.saveStore(v);
      this.setState({ store: v, menuListLoading: true });
      const req = {};
      req.EnterpriseId = storageUtil.getEnt();
      req.StoreNumber = storageUtil.getStore();
      const re = await reqMenuList(req);
      if (re.status === 200 && re.data) {
        this.setState({ menuList: re.data || [], menuListLoading: false });
      } else if (re === 'Server Error') {
        this.setState({ menuListLoading: false });
      }
    } else {
      if (!menuDisabled) {
        this.setState({ menuDisabled: true });
      }
    }
    if (menuDisabled) {
      this.setState({ menuDisabled: false });
    }
  };

  handleMenuChange = v => {
    const { menuList } = this.state;
    this.setState({ menuSelected: v });
    const menu = menuList.find(i => i.id === parseInt(v));
    if (menu) {
      this.setState({ menu, btnText: 'Edit Menu' });
    } else {
      this.setState({ menu: {}, btnText: 'New Menu' });
    }
  };

  handleBtnClick = value => {
    const { ent, store, menu, ents, stores } = this.state;
    if (menu && menu.menuGroups && menu.menuGroups.length > 0) {
      menu.menuGroups = menu.menuGroups.map(i => {
        i.key = nanoid();
        return i;
      });
    }

    if (!ent) {
      this.props.receiveError('Please select an Enterprise!');
      return;
    }
    if (!store) {
      this.props.receiveError('Please select a store!');
      return;
    }
    this.props.receiveError('');
    let isNew;
    if (value === 'Edit Menu') {
      this.props.initEditMenu(menu);
      isNew = false;
    }
    if (value === 'New Menu') {
      this.props.initNewMenu();
      isNew = true;
    }
    this.props.history.replace({
      pathname: '/menu',
      state: { isNew: isNew, ents: ents, stores: stores },
    });
  };

  handleSelectionClear = () => {
    this.setState({
      ent: null,
      store: null,
      menuSelected: null,
      storeDisabled: true,
      menuDisabled: true,
      btnText: 'New Menu',
    });
  };

  handleDeliPartnerSetupBtnClick = async () => {
    storageUtil.removeEnt();
    storageUtil.removeStore();
    this.setState({ spinning: true });
    const re = await reqPartners();
    if (re.status === 200 && re.data) {
      this.props.receivePartners(re.data);
      this.setState({ spinning: false });
      this.props.history.replace('/partners');
    } else if (re === 'Server Error') {
      this.setState({ spinning: false });
    }
  };

  handleConfigDeliPartnerStoresBtnClick = async () => {
    storageUtil.removeEnt();
    storageUtil.removeStore();
    this.setState({ spinning: true });
    const re = await reqPartners();
    if (re.status === 200 && re.data) {
      this.props.receivePartners(re.data);
      this.setState({ spinning: false });
      this.props.history.replace('/stores');
    } else if (re === 'Server Error') {
      this.setState({ spinning: false });
    }
  };

  componentDidMount() {
    this.init();
  }

  render() {
    const {
      btnText,
      storeDisabled,
      menuDisabled,
      menuListLoading,
      storeListLoading,
      menuList,
      stores,
      ents,
      ent,
      store,
      menuSelected,
      userType,
      spinning,
    } = this.state;

    const menuListOptions = menuList.map(i => (
      <Option key={i.id} value={i.id}>
        {i.menuName}
      </Option>
    ));

    const entsOptions = ents.map(i => (
      <Option key={i.enterpriseId} value={i.enterpriseId}>
        {i.description}
      </Option>
    ));

    const storesOptions = stores.map(i => (
      <Option key={i.storeNumber} value={i.storeNumber}>
        {i.storeName}
      </Option>
    ));

    return (
      <Spin spinning={spinning}>
        <Row style={{ backgroundColor: '#e4f2ff', padding: '20px' }}>
          <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }}>
            <Row justify="center">
              <Selection
                toolTip="Enterprise"
                showSearch
                width={300}
                value={ent}
                data={entsOptions}
                required={true}
                placeholder="Select an Enterprise"
                onChange={(value, option) => {
                  this.handleEntChange(value, option);
                }}
              />
            </Row>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }}>
            <Row justify="center">
              <Selection
                toolTip="Store"
                loading={storeListLoading}
                showSearch
                disabled={storeDisabled}
                width={300}
                required={true}
                value={store}
                data={storesOptions}
                placeholder="Select a store"
                onChange={(value, option) => {
                  this.handleStoreChange(value, option);
                }}
              />
            </Row>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }}>
            <Row justify="center">
              <Selection
                toolTip="Menu"
                showSearch
                allowClear
                disabled={menuDisabled}
                width={300}
                placeholder="Select a menu"
                loading={menuListLoading}
                value={menuSelected}
                data={menuListOptions}
                onChange={(value, option) => {
                  this.handleMenuChange(value, option);
                }}
              />
            </Row>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }}>
            <Row style={{ margin: 10 }} align="middle">
              <Col span={12}>
                <Row justify="center">
                  <div onClick={this.handleSelectionClear}>
                    <Typography.Link>Clear</Typography.Link>
                  </div>
                </Row>
              </Col>
              <Col span={12}>
                <Row justify="end">
                  <VTButton
                    text={btnText}
                    style={{ width: 100, height: 35, marginBottom: 5 }}
                    onClick={value => this.handleBtnClick(value)}
                  />
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Divider style={{ borderColor: '#fff' }} />
        {userType === 9 ? (
          <Row style={{ backgroundColor: '#e4f2ff', padding: '20px' }} justify="end">
            {' '}
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <Row justify="end" style={{ margin: 10 }}>
                <VTButton
                  text="Delivery Partner Setup"
                  style={{ width: 300, height: 35, marginBottom: 5 }}
                  onClick={value => this.handleDeliPartnerSetupBtnClick(value)}
                />
              </Row>
            </Col>
          </Row>
        ) : (
          <></>
        )}
        <Divider style={{ borderColor: '#fff' }} />
        {userType === 9 ? (
          <Row style={{ backgroundColor: '#e4f2ff', padding: '20px' }} justify="end">
            {' '}
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <Row justify="end" style={{ margin: 10 }}>
                <VTButton
                  text="Configure Delivery Partner Stores"
                  style={{ width: 300, height: 35, marginBottom: 5 }}
                  onClick={value => this.handleConfigDeliPartnerStoresBtnClick(value)}
                />
              </Row>
            </Col>
          </Row>
        ) : (
          <></>
        )}
      </Spin>
    );
  }
}
export default connect(state => ({ user: state.user, error: state.error }), {
  receiveError,
  initEditMenu,
  initNewMenu,
  receivePartners,
})(Console);
