import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Divider, Row, Select } from 'antd';
import { receiveError, receivePcMap, delPcMap } from '../../redux/actions';
import VTButton from '../VTButton';
import ProfitCenterMappingRow from '../ProfitCMappingRow';
import { orderType } from '../../config/orderType';
import { reqProfitCentres } from '../../api';
import storageUtil from '../../util/storeUtil';

const { Option } = Select;

class ProfitCenterMapping extends Component {
  count = 1;
  state = {
    data: [],
    loading: false,
    pcList: [],
  };

  init = async () => {
    this.setState({ loading: true });
    const { profitcentreMappings } = this.props.menu;
    const req = {};
    req.EnterpriseId = storageUtil.getEnt();
    req.StoreNumber = storageUtil.getStore();
    const re = await reqProfitCentres(req);
    if (re.status === 200 && re.data) {
      this.setState({ loading: false, pcList: re.data });
    } else if (re === 'Server Error') {
      this.setState({ loading: false });
    }
    if (!profitcentreMappings || profitcentreMappings.length === 0) {
      this.setState({
        data: [
          { key: this.count, orderType: null, profitCentreCode: null, profitCentreName: null },
        ],
      });
    } else {
      const arr = [];
      profitcentreMappings.map(i => {
        ++this.count;
        const obj = {};
        obj.key = this.count;
        obj.orderType = i.orderType;
        obj.profitCentreCode = i.profitCentreCode;
        obj.profitCentreName = i.profitCentreName;
        arr.push(obj);
        return i;
      });
      this.setState({ data: arr });
    }
  };

  checkDuplicate = (v, o, key, field) => {
    const { data } = this.state;
    const row = data.find(i => i.key === key);
    if ((field === 'ot' && !row.profitCentreCode) || (field === 'pc' && !row.orderType))
      return false;
    const strArr = [];
    const othersArr = data.filter(i => i.key !== key);
    othersArr.map(i => {
      const str = i.orderType + ',' + i.profitCentreCode;
      strArr.push(str);
      return i;
    });

    let otStr = '',
      pcStr = '';
    if (field === 'ot') {
      otStr = v;
      pcStr = row.profitCentreCode;
    } else {
      otStr = row.orderType;
      pcStr = v;
    }
    const cStr = otStr + ',' + pcStr;
    return strArr.indexOf(cStr) >= 0;
  };

  checkRow = key => {
    const { data } = this.state;
    const arr = data.filter(i => !i.orderType || !i.profitCentreCode);
    if (arr.length === 0) {
      this.props.receiveError('');
      const pcMappings = [];
      data.map(i => {
        const obj = {};
        obj.orderType = i.orderType;
        obj.profitCentreCode = i.profitCentreCode;
        obj.profitCentreName = i.profitCentreName;
        pcMappings.push(obj);
        return i;
      });
      this.props.receivePcMap(pcMappings);
    }
  };

  handleOrderTypeChange = (v, o, key) => {
    const { data } = this.state;
    const field = 'ot';
    const isDuplicate = this.checkDuplicate(v, o, key, field);
    if (isDuplicate) {
      const qRow = data.filter(i => i.key === key);
      qRow[0].orderType = null;
      this.setState({ data });
      this.props.receiveError("That's going to be a duplicate!");
      return;
    }
    if (!isDuplicate) {
      this.props.receiveError('');
    }
    const dataItem = data.filter(i => i.key === key);
    if (v && o) {
      dataItem[0].orderType = o.value;
    }
    this.setState({ data });
    this.checkRow(key);
  };

  handlePCChange = (v, o, key) => {
    const { data } = this.state;
    const field = 'pc';
    const isDuplicate = this.checkDuplicate(v, o, key, field);
    if (isDuplicate) {
      const qRow = data.filter(i => i.key === key);
      qRow[0].profitCentreCode = null;
      this.setState({ data });
      this.props.receiveError("That's going to be a duplicate!");
      return;
    }
    if (!isDuplicate) {
      this.props.receiveError('');
    }
    const dataItem = data.filter(i => i.key === key);
    if (v && o) {
      dataItem[0].profitCentreCode = o.value;
      dataItem[0].profitCentreName = o.children;
    }
    this.setState({ data });
    this.checkRow(key);
  };

  handleAddPCMapping = () => {
    ++this.count;
    const { data } = this.state;
    const arr = data.filter(i => !i.orderType || !i.profitCentreCode);
    if (arr.length) {
      this.props.receiveError('Please complete or delete the incomplete rows!');
      return;
    }
    const newRow = {
      key: this.count,
      orderType: null,
      profitCentreCode: null,
      profitCentreName: null,
    };
    this.setState({ data: [...data, newRow] });
    this.props.receiveError('');
  };

  handleMappingDelete = item => {
    const { data } = this.state;
    const arr = data.filter(i => !i.orderType || !i.profitCentreCode);
    if (arr.length === 1 && arr[0].key === item.key) {
      this.props.receiveError('');
    }
    const newData = data.filter(i => i.key !== item.key);
    this.setState({ data: newData });
    this.props.delPcMap(item);
  };

  componentDidMount() {
    this.init();
  }

  render() {
    const { data, loading, pcList } = this.state;
    const orderTypeOptions = orderType.map(i => (
      <Option key={i.id} value={i.id}>
        {i.description}
      </Option>
    ));
    const pcOptions = pcList.map(i => (
      <Option key={i.profitCentreCode} value={i.profitCentreCode}>
        {i.profitCentreName}
      </Option>
    ));

    return (
      <>
        {data.map(i => (
          <ProfitCenterMappingRow
            key={i.key}
            mapping={i}
            loading={loading}
            orderTypeOptions={orderTypeOptions}
            pcOptions={pcOptions}
            onOrderTypeChange={(v, o) => this.handleOrderTypeChange(v, o, i.key)}
            onPCChange={(v, o) => this.handlePCChange(v, o, i.key)}
            onMappingDelete={() => this.handleMappingDelete(i)}
          />
        ))}
        <Divider style={{ borderColor: '#fff', marginBottom: '0' }} />
        <Row justify="end">
          <VTButton
            text="Add a Mapping"
            style={{ width: 130, height: 35 }}
            onClick={this.handleAddPCMapping}
          />
        </Row>
      </>
    );
  }
}
export default connect(
  state => ({
    error: state.error,
    menu: state.menu,
  }),
  { receiveError, receivePcMap, delPcMap },
)(ProfitCenterMapping);
