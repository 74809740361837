import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Select } from 'antd';
import Selection from '../Selection';
import { reqPmtMap } from '../../api';
import { receivePmtInfo } from '../../redux/actions';
import storageUtil from '../../util/storeUtil';

const { Option } = Select;

class PaymentMapping extends Component {
  state = { loading: false, list: [], selectedOption: null };

  init = async () => {
    this.setState({ loading: true });
    const req = {};
    req.EnterpriseId = storageUtil.getEnt();
    req.StoreNumber = storageUtil.getStore();
    const re = await reqPmtMap(req);
    const { pmtinfo } = this.props.menu;
    if (re.status === 200 && re.data) {
      this.setState({ loading: false, list: re.data });
      const item = re.data.find(i => i.code === pmtinfo.code);
      if (item && item.code) {
        this.setState({ selectedOption: item.code });
      }
    } else {
      this.setState({ loading: false });
    }
  };
  handlePmtMappingChange = v => {
    const { list } = this.state;
    let item;
    if (v === null || v === undefined) {
      item = {};
    } else {
      item = list.find(i => i.code === v);
    }
    this.setState({ selectedOption: v });
    this.props.receivePmtInfo(item);
  };

  componentDidMount() {
    this.init();
  }
  render() {
    const { loading, list, selectedOption } = this.state;
    const pmtMapOptions = list.map(i => (
      <Option key={i.code} value={i.code}>
        {i.description}
      </Option>
    ));
    return (
      <Selection
        toolTip="Payment Mapping"
        width={220}
        loading={loading}
        data={pmtMapOptions}
        value={selectedOption}
        allowClear={true}
        placeholder="Select Payment Mapping"
        onChange={(value, option) => this.handlePmtMappingChange(value, option)}
      />
    );
  }
}
export default connect(
  state => ({
    menu: state.menu,
  }),
  { receivePmtInfo },
)(PaymentMapping);
