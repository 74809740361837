import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Select, Divider, Spin, Input, Tooltip, Typography, message } from 'antd';
import { receiveError, receivePartnerStore } from '../../redux/actions';
import { reqStores, reqUpdatePartner } from '../../api';
import Selection from '../../components/Selection';
import PartnerStoreTable from '../../components/PartnerStoreTable';
import VTButton from '../../components/VTButton';
import { deliPartnerOptions } from '../../config/partnerId';
import './index.css';

const { Option } = Select;

class Stores extends Component {
  state = {
    partner: null,
    ent: null,
    ents: [],
    stores: [],
    spinning: false,
    addStoreValue: null,
    bIdValue: '',
    sIdValue: '',
  };

  init = () => {
    const { partners } = this.props;
    if (partners.length === 0) {
      return this.props.history.replace('/console');
    }
    this.props.receiveError('');
    const { clouds } = this.props.user;
    let ents = JSON.parse(JSON.stringify(clouds));
    ents = ents.sort((a, b) => a.description.localeCompare(b.description));
    this.setState({ ents });
  };

  handleDeliPartyChange = (v, o) => {
    const { error } = this.props;
    if (error !== '') {
      this.props.receiveError('');
    }
    const { ent } = this.state;
    this.setState({ partner: v });
    if (ent !== null) {
      this.setState({ ent: null });
    }
    this.handleAddClear();
  };

  handleEntChange = async (v, o) => {
    this.setState({ ent: v, spinning: true });
    if (v) {
      const re = await reqStores(v);
      if (re === 'Ent Error' || re === 'No Cloud Data') {
        this.setState({ spinning: false });
        this.handleClear();
      } else if (re.status === 200 && re.data.length >= 0) {
        this.setState({ stores: re.data, spinning: false });
      } else if (re === 'Server Error') {
        this.setState({ spinning: false });
      }
    }
  };

  handleClear = () => {
    this.setState({ partner: null, ent: null });
  };

  handleBIdChange = e => {
    this.setState({ bIdValue: e.target.value });
  };

  handleSIdChange = e => {
    this.setState({ sIdValue: e.target.value });
  };

  handleAddStoreChange = (v, o) => {
    this.setState({ addStoreValue: v });
  };

  handleAddClear = () => {
    this.setState({ addStoreValue: null, bIdValue: '', sIdValue: '' });
  };

  handleAddStore = () => {
    const { addStoreValue, bIdValue, sIdValue, partner, ent } = this.state;
    if (!addStoreValue || !bIdValue || !sIdValue) return;
    const obj = {};
    obj.partnerStoreId = sIdValue;
    obj.storeNumber = addStoreValue;
    obj.enterpriseId = ent;
    obj.partnerType = partner;
    obj.businessId = bIdValue;
    this.props.receivePartnerStore(obj);
    this.handleAddClear();
  };

  handleSaveBtn = async () => {
    this.setState({ spinning: true });
    const { partners } = this.props;
    const { partner } = this.state;
    const partnerObj = partners.find(i => i.partnerType === partner);
    const re = await reqUpdatePartner(partnerObj);
    if (re.status === 200) {
      this.setState({ spinning: false });
      message.success('Partner stores info successfully updated!');
    } else if (re === 'Server Error') {
      this.setState({ spinning: false });
    }
  };

  componentDidMount() {
    this.init();
  }

  render() {
    const { partner, ent, ents, spinning, stores, addStoreValue, bIdValue, sIdValue } = this.state;
    const { error, partners } = this.props;
    const partnerObj = partners.find(i => i.partnerType === partner) || {};
    const { partnerStores } = partnerObj || [];
    let tempArr = [];
    if (partnerStores) {
      tempArr = partnerStores.filter(i => i.enterpriseId === ent);
    }
    const storesArr = stores.filter(i => tempArr.every(j => i.storeNumber !== j.storeNumber));

    const entsOptions = ents.map(i => (
      <Option key={i.enterpriseId} value={i.enterpriseId}>
        {i.description}
      </Option>
    ));
    const storesOptions = storesArr.map(i => (
      <Option key={i.storeNumber} value={i.storeNumber}>
        {i.storeName}
      </Option>
    ));

    return (
      <Spin spinning={spinning}>
        <Row style={{ backgroundColor: '#e4f2ff', padding: '20px' }} justify="space-around">
          <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }}>
            <Row justify="center" align="middle">
              <Selection
                toolTip="Delivery Partner"
                width={220}
                data={deliPartnerOptions}
                placeholder="Select a Delivery Partner"
                value={partner}
                onChange={(value, option) => {
                  this.handleDeliPartyChange(value, option);
                }}
              />
            </Row>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }}>
            <Row justify="center" align="middle">
              <Selection
                toolTip="Enterprise"
                showSearch
                width={220}
                data={entsOptions}
                disabled={partner === null}
                placeholder="Select an Enterprise"
                value={ent}
                onChange={(value, option) => {
                  this.handleEntChange(value, option);
                }}
              />
            </Row>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }}></Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }}>
            <Row justify="space-around" align="middle">
              <div style={{ margin: 10 }} onClick={this.handleClear}>
                <Typography.Link>Clear</Typography.Link>
              </div>
              <VTButton
                text="Save"
                disabled={error !== '' || !partner || !ent}
                style={{ width: 100, height: 35, margin: '10px 10px 15px 10px' }}
                onClick={this.handleSaveBtn}
              />
            </Row>
          </Col>
        </Row>
        <Divider style={{ borderColor: '#fff', marginBottom: '0' }} />
        <Row style={{ backgroundColor: '#e4f2ff', padding: '20px' }} justify="space-around">
          <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }}>
            <Row justify="center" align="middle">
              <Selection
                toolTip="Visual Touch Store"
                width={220}
                required={true}
                data={storesOptions}
                placeholder="Select a Visual Touch Store"
                value={addStoreValue}
                disabled={!partner || !entsOptions}
                onChange={(value, option) => {
                  this.handleAddStoreChange(value, option);
                }}
              />
            </Row>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }}>
            <Row justify="center" align="middle">
              <Tooltip title="Business ID">
                <div className="store-input" style={{ position: 'relative' }}>
                  <Input
                    placeholder="Enter Business ID"
                    style={{ width: 220, margin: 10 }}
                    value={bIdValue}
                    disabled={!addStoreValue}
                    onChange={e => {
                      this.handleBIdChange(e);
                    }}
                  />
                </div>
              </Tooltip>
            </Row>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }}>
            <Row justify="center" align="middle">
              <Tooltip title="Partner Store ID">
                <div className="store-input" style={{ position: 'relative' }}>
                  <Input
                    placeholder="Enter Partner Store ID"
                    style={{ width: 220, margin: 10 }}
                    value={sIdValue}
                    disabled={!addStoreValue}
                    onChange={e => {
                      this.handleSIdChange(e);
                    }}
                  />
                </div>
              </Tooltip>
            </Row>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }}>
            <Row justify="space-around" align="middle">
              <Typography.Link style={{ margin: 10 }} onClick={this.handleAddClear}>
                Clear
              </Typography.Link>
              <VTButton
                text="Add"
                disabled={
                  !partner || !ent || !addStoreValue || !bIdValue || !sIdValue || error !== ''
                }
                style={{ width: 100, height: 35, margin: '10px 10px 15px 10px' }}
                onClick={this.handleAddStore}
              />
            </Row>
          </Col>
        </Row>
        <Divider style={{ borderColor: '#fff' }} />
        <PartnerStoreTable
          partner={partner}
          ent={ent}
          stores={stores}
          storesOptions={storesOptions}
        />
      </Spin>
    );
  }
}

export default connect(
  state => ({ error: state.error, user: state.user, partners: state.partners }),
  {
    receiveError,
    receivePartnerStore,
  },
)(Stores);
