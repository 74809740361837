import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { headerDate } from '../../util/date';
import { logout } from '../../redux/actions';
import logo from '../../assets/logos/logo-dark-83a00d5f.webp';
import './index.css';

const { confirm } = Modal;

class VTHeader extends Component {
  state = {
    date: '',
  };

  getDate = () => {
    const date = headerDate();
    this.setState({ date: date || '' });
  };

  showLogoutConfirm = () => {
    confirm({
      title: 'Sure to log out?',
      icon: <ExclamationCircleOutlined />,
      content: '',

      onOk: () => {
        this.handleLogout();
      },

      onCancel: () => {},
    });
  };

  handleLogout = () => {
    this.props.logout();
    this.props.history.replace('/');
  };

  componentDidMount() {
    this.getDate();
  }

  render() {
    const { date } = this.state;
    const { user } = this.props;

    return (
      <div className="header-wrap">
        <div className="header-top">
          <div className="header-logo">
            <img src={logo} alt="Logo" />
          </div>
          <div className="header-date">{date}</div>
          <div className="header-user">Signed in as {user.firstName || ''}</div>
          <div className="header-logout" onClick={this.showLogoutConfirm}>
            <span>LOG OUT</span>
          </div>
        </div>
      </div>
    );
  }
}
export default connect(
  state => ({
    user: state.user,
  }),
  { logout },
)(withRouter(VTHeader));
