import React, { Component } from 'react';
import { Row, Col, Popconfirm, Typography, Input } from 'antd';
import { SwapOutlined } from '@ant-design/icons';
import Selection from '../Selection';
import { OrckCats } from '../../config/OrckCats';

export default class OrchCatMappingRow extends Component {
  state = {
    mapping: null,
  };

  init = () => {
    const { mapping } = this.props;
    this.setState({ mapping });
  };

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps, prevState) {
    const { mapping } = this.props;
    if (prevState.mapping !== mapping) {
      this.init();
    }
  }

  render() {
    const {
      VTCats,
      onOrchCatChange,
      onVTCatChange,
      onOrchLocationIDChange,
      onOrchCatMapDescChange,
      onOrchMapDelete,
      mapping,
    } = this.props;
    return (
      <Row gutter={16} align="middle" className="vt-row">
        <Col>
          <Selection
            toolTip="Orchestra Category"
            width={220}
            data={OrckCats}
            placeholder="Select Orckestra Category"
            value={mapping === null ? null : mapping.orchestraCategory}
            onChange={(value, option) => onOrchCatChange(value, option)}
          />
        </Col>
        <Col style={{ margin: 10 }}>
          <SwapOutlined />
        </Col>
        <Col>
          <Selection
            toolTip="VT Category/Panel"
            width={220}
            data={VTCats}
            placeholder="Select VT Category/Panel"
            value={mapping === null ? null : mapping.vtCategory}
            onChange={(value, option) => onVTCatChange(value, option)}
          />
        </Col>
        <Col>
          <Input
            style={{ width: '260px', margin: 10 }}
            addonBefore="Orckestra Location ID"
            disabled={!(mapping.orchestraCategory && mapping.vtCategory)}
            value={mapping === null ? null : mapping.orchestraLocId}
            onChange={e => {
              onOrchLocationIDChange(e);
            }}
          />
        </Col>
        <Col>
          <Input
            style={{ width: '260px', margin: 10 }}
            addonBefore="Description"
            disabled={!(mapping.orchestraCategory && mapping.vtCategory)}
            value={mapping === null ? null : mapping.description}
            onChange={e => {
              onOrchCatMapDescChange(e);
            }}
          />
        </Col>
        <Col>
          <div style={{ margin: 10 }}>
            <Popconfirm title="Sure to delete?" onConfirm={() => onOrchMapDelete()}>
              <Typography.Link>Delete</Typography.Link>
            </Popconfirm>
          </div>
        </Col>
      </Row>
    );
  }
}
