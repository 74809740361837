import React, { Component } from 'react';
import { connect } from 'react-redux';
import { initNewMenu } from '../../redux/actions';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Layout, Breadcrumb, Row, Col } from 'antd';
import { HomeFilled } from '@ant-design/icons';
import VTheader from '../../components/VTHeader';
import Error from '../../components/Error';
import Console from '../Console';
import Menu from '../Menu';
import Partners from '../Partners';
import Stores from '../Stores';
import { capitalize } from '../../util/capitalize';

const { Header, Content, Footer } = Layout;

class Home extends Component {
  state = {
    hasError: '',
  };

  handleGoToConsole = () => {
    this.props.initNewMenu();
    this.props.history.push('/console');
  };

  static getDerivedStateFromError(error) {
    return { hasError: error };
  }

  componentDidCatch() {
    console.log('Error');
  }

  handlePageScroll = pageScrollY => {
    if (pageScrollY >= 70) {
      this.setState({ showErrorBtm: true });
    } else {
      if (this.state.showErrorBtm) {
        this.setState({ showErrorBtm: false });
      }
    }
  };

  componentDidMount() {
    window.addEventListener('scroll', () => {
      const pageScrollY = window.scrollY;
      this.handlePageScroll(pageScrollY);
    });
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', () => {
      const pageScrollY = window.scrollY;
      this.handlePageScroll(pageScrollY);
    });
  }

  render() {
    const { hasError, showErrorBtm } = this.state;
    const { error, user } = this.props;
    const { pathname } = this.props.location || '';
    const bCrumb = capitalize(pathname);

    if (!user || !user.apiToken) {
      return <Redirect to="/login" />;
    }

    return (
      <Layout>
        <Header>
          <VTheader />
        </Header>
        <Content style={{ padding: '0 50px', overflow: 'scroll' }}>
          <Row justify="space-between" align="middle">
            <Col xs={{ span: 24 }} sm={{ span: 6 }}>
              <Row justify="start">
                <Breadcrumb
                  style={{
                    margin: '8px 0',
                    lineHeight: '24px',
                  }}
                  separator={
                    <span
                      style={{
                        fontSize: '24px',
                        color: '#bfbfbf',
                        paddingLeft: '6px',
                        paddingRight: '6px',
                      }}
                    >
                      /
                    </span>
                  }
                >
                  <Breadcrumb.Item
                    onClick={() => this.handleGoToConsole()}
                    style={{ cursor: 'pointer', fontWeight: 'bold' }}
                  >
                    <HomeFilled spin={false} style={{ color: '#001529', fontSize: '24px' }} />
                  </Breadcrumb.Item>
                  {bCrumb === 'Console' ? (
                    <></>
                  ) : (
                    <Breadcrumb.Item>
                      <div
                        style={{
                          color: '#bfbfbf',
                          fontSize: '24px',
                          paddingTop: '3px',
                          fontFamily: "'sans-serif', serif",
                        }}
                      >
                        {bCrumb}
                      </div>
                    </Breadcrumb.Item>
                  )}
                </Breadcrumb>
              </Row>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 12 }}>
              <Row justify="center">
                <Error errormsg={error} />
              </Row>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 6 }}></Col>
          </Row>
          <div className="site-layout-content">
            {hasError ? (
              <h2>Something's wrong. Please try again later.</h2>
            ) : (
              <Switch>
                <Redirect from="/" exact to="/console" />
                <Route path="/console" component={Console} />
                <Route path="/menu" component={Menu} />
                <Route path="/partners" component={Partners} />
                <Route path="/stores" component={Stores} />
              </Switch>
            )}
            <Row justify="center">{showErrorBtm ? <Error errormsg={error} /> : <></>}</Row>
          </div>
        </Content>
        <Footer style={{ textAlign: 'center', color: '#001529' }}>
          Copyright ©{' '}
          <a href="https://www.visualtouchpos.com/" target="_blank" rel="noreferrer">
            {' '}
            Visual Information Products Inc.
          </a>
          &nbsp; All Rights Reserved.
        </Footer>
      </Layout>
    );
  }
}
export default connect(state => ({ user: state.user, error: state.error }), { initNewMenu })(Home);
