import React, { Component } from 'react';
import { Input, Row } from 'antd';
import Selection from '../Selection';
import { deliPartnerOptions } from '../../config/partnerId';

export default class PartnerSetup extends Component {
  state = {
    partnerType: null,
    partnerName: '',
    clientId: '',
    secrete: '',
    authUrl: '',
    tokenUrl: '',
    apiUrl: '',
    azureStorageContainer: '',
    azureSASToken: '',
    azureEndPoint: '',
    partnerStores: [],
  };

  init = () => {
    const { partner } = this.props;
    this.setState({
      partnerType: partner.partnerType || null,
      partnerName: partner.partnerName || '',
      clientId: partner.clientId || '',
      secrete: partner.secrete || '',
      authUrl: partner.authUrl || '',
      tokenUrl: partner.tokenUrl || '',
      apiUrl: partner.apiUrl || '',
      azureStorageContainer: partner.azureStorageContainer || '',
      azureSASToken: partner.azureSASToken || '',
      azureEndPoint: partner.azureEndPoint || '',
      partnerStores: partner.partnerStores || [],
    });
  };

  updatePartnerChanges = () => {
    const {
      partnerType,
      partnerName,
      clientId,
      secrete,
      authUrl,
      tokenUrl,
      apiUrl,
      azureStorageContainer,
      azureSASToken,
      azureEndPoint,
      partnerStores,
    } = this.state;
    const partner = {};
    partner.partnerType = partnerType;
    partner.partnerName = partnerName;
    partner.clientId = clientId;
    partner.secrete = secrete;
    partner.authUrl = authUrl;
    partner.tokenUrl = tokenUrl;
    partner.apiUrl = apiUrl;
    partner.azureStorageContainer = azureStorageContainer;
    partner.azureSASToken = azureSASToken;
    partner.azureEndPoint = azureEndPoint;
    partner.partnerStores = partnerStores;

    this.props.onPartnerChange(partner);
  };

  handleDeliPartyChange = (v, o) => {};

  handleChange = (dataType, e) => {
    this.setState({ [dataType]: e.target.value }, () => {
      this.updatePartnerChanges();
    });
  };

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps, prevState) {
    const { partner } = this.props;
    if (prevProps.partner !== partner) {
      this.init();
    }
  }

  render() {
    const { partner } = this.props;
    const {
      partnerType,
      clientId,
      secrete,
      authUrl,
      tokenUrl,
      apiUrl,
      azureStorageContainer,
      azureSASToken,
      azureEndPoint,
    } = this.state;

    return (
      <div>
        <Row align="middle" className="vt-row">
          <div style={{ width: 200, fontWeight: '600' }}>Select Delivery Partner</div>
          <Selection
            data={deliPartnerOptions}
            width={400}
            value={partner.partnerType}
            disabled={partner.partnerType}
            onChange={(value, option) => {
              this.handleDeliPartyChange(value, option);
            }}
          />
        </Row>
        <Row align="middle" className="vt-row">
          <div style={{ width: 200, fontWeight: '600' }}>Delivery Partner Client Id</div>
          <Input
            style={{ width: 400, margin: 10 }}
            value={clientId}
            onChange={e => {
              this.handleChange('clientId', e);
            }}
          />
        </Row>
        <Row align="middle" className="vt-row">
          <div style={{ width: 200, fontWeight: '600' }}>Client Secret</div>
          <Input
            style={{ width: 400, margin: 10 }}
            value={secrete}
            onChange={e => {
              this.handleChange('secrete', e);
            }}
          />
        </Row>
        <Row align="middle" className="vt-row">
          <div style={{ width: 200, fontWeight: '600' }}>Authorization URL</div>
          <Input
            style={{ width: 400, margin: 10 }}
            value={authUrl}
            onChange={e => {
              this.handleChange('authUrl', e);
            }}
          />
        </Row>
        <Row align="middle" className="vt-row">
          <div style={{ width: 200, fontWeight: '600' }}>Token URL</div>
          <Input
            style={{ width: 400, margin: 10 }}
            value={tokenUrl}
            onChange={e => {
              this.handleChange('tokenUrl', e);
            }}
          />
        </Row>
        <Row align="middle" className="vt-row">
          <div style={{ width: 200, fontWeight: '600' }}>Api URL</div>
          <Input
            style={{ width: 400, margin: 10 }}
            value={apiUrl}
            onChange={e => {
              this.handleChange('apiUrl', e);
            }}
          />
        </Row>
        {partnerType === 6 ? (
          <>
            <Row align="middle" className="vt-row">
              <div style={{ width: 200, fontWeight: '600' }}>Storage Constainer</div>
              <Input
                style={{ width: 400, margin: 10 }}
                value={azureStorageContainer}
                onChange={e => {
                  this.handleChange('azureStorageContainer', e);
                }}
              />
            </Row>
            <Row align="middle" className="vt-row">
              <div style={{ width: 200, fontWeight: '600' }}>SAS Token</div>
              <Input
                style={{ width: 400, margin: 10 }}
                value={azureSASToken}
                onChange={e => {
                  this.handleChange('azureSASToken', e);
                }}
              />
            </Row>
            <Row align="middle" className="vt-row">
              <div style={{ width: 200, fontWeight: '600' }}>Azure Storage EndPoint</div>
              <Input
                style={{ width: 400, margin: 10 }}
                value={azureEndPoint}
                onChange={e => {
                  this.handleChange('azureEndPoint', e);
                }}
              />
            </Row>
          </>
        ) : (
          <></>
        )}
      </div>
    );
  }
}
