import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Popconfirm, Typography } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { receiveError, updatePartnerStores, delPartnerStore } from '../../redux/actions';
import EditableTable from '../EditableTable';

class PartnerStoreTable extends Component {
  getUpdatedPartnerStoresData = data => {
    const isEmpty = data.filter(i => !i.partnerStoreId || !i.businessId).length > 0;
    if (isEmpty) {
      this.props.receiveError('"Business ID" or "Partner Store ID" cannot be empty!');
      return;
    }
    if (!isEmpty) {
      this.props.receiveError('');
      this.props.updatePartnerStores(data);
    }
  };

  handleDelStore = row => {
    this.props.delPartnerStore(row);
  };

  render() {
    const { partner, ent, stores, partners } = this.props;
    const partnerObj = partners.find(i => i.partnerType === partner) || {};
    const { partnerStores } = partnerObj || [];
    let arr = [];
    if (partnerStores) {
      arr = partnerStores.filter(i => i.enterpriseId === ent);
    }
    const dataSource = JSON.parse(JSON.stringify(arr));

    const columns = [
      {
        title: () => (
          <Row justify="space-between" align="middle">
            <span>Business ID</span>
            <EditOutlined style={{ color: '#a5a5a5', marginRight: 2 }} />
          </Row>
        ),
        dataIndex: 'businessId',
        editable: true,
      },
      {
        title: () => (
          <Row justify="space-between" align="middle">
            <span>Partner Store ID</span>
            <EditOutlined style={{ color: '#a5a5a5', marginRight: 2 }} />
          </Row>
        ),
        dataIndex: 'partnerStoreId',
        editable: true,
      },
      {
        title: 'Visual Touch Store',
        render: record => {
          let store = {};
          if (stores) {
            store = stores.find(i => i.storeNumber === record.storeNumber);
          }
          return <span>{store && store.storeName}</span>;
        },
      },
      {
        title: 'Edit',
        render: record => (
          <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelStore(record)}>
            <Typography.Link>Delete</Typography.Link>
          </Popconfirm>
        ),
      },
    ];

    return (
      <EditableTable
        size="middle"
        rowKey="storeNumber"
        tableColumns={columns}
        tableDataSource={dataSource}
        pagination={false}
        itemSize={false}
        itemMods={false}
        oDes={false}
        pStore={true}
        getUpdatedData={updatedData => this.getUpdatedPartnerStoresData(updatedData)}
      />
    );
  }
}

export default connect(
  state => ({ error: state.error, user: state.user, partners: state.partners }),
  {
    receiveError,
    updatePartnerStores,
    delPartnerStore,
  },
)(PartnerStoreTable);
