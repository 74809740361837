import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Table,
  Button,
  Select,
  Divider,
  Typography,
  Modal,
  Input,
  Popconfirm,
  Spin,
} from 'antd';
import Selection from '../Selection';
import Error from '../Error';
import { receiveMenuGroup, receiveItems, delMenuItems, delMenuGroup } from '../../redux/actions';
import { reqPanels } from '../../api';
import storageUtil from '../../util/storeUtil';
import './index.css';
import { nanoid } from '@reduxjs/toolkit';

const { Option } = Select;

class MultiPGModal extends Component {
  state = {
    spinning: false,
    pOrGValue: null,
    sOption: null,
    inputValue: '',
    addedPanels: [],
    err: '',
    panelsExcl: [],
    panels: [],
  };

  columns = [
    {
      title: 'Panel',
      dataIndex: 'description',
    },
    {
      title: 'Action',
      render: (text, record) => {
        return (
          <Popconfirm title="Sure to delete?" onConfirm={() => this.handleGroupDelete(record)}>
            <Typography.Link>Delete</Typography.Link>
          </Popconfirm>
        );
      },
    },
  ];

  init = async () => {
    const { multiPanelSelected } = this.props;
    if (multiPanelSelected) {
      this.setState({
        spinning: true,
        inputValue: multiPanelSelected.description,
        addedPanels: multiPanelSelected.subList,
      });
    } else {
      this.setState({ spinning: true, inputValue: '', addedPanels: [] });
    }
    const req = {};
    req.EnterpriseId = storageUtil.getEnt();
    req.StoreNumber = storageUtil.getStore();
    let arr = [];
    const resultPanels = await reqPanels(req);
    if (resultPanels.status === 200 && resultPanels.data) {
      arr = resultPanels.data.filter(
        i => i.panelType !== 1 && i.panelType !== 2 && i.panelType !== 5,
      );
      this.setState({ panels: arr, spinning: false }, () => {
        this.updateOptions();
      });
    } else if (resultPanels === 'Server Error') {
      this.setState({ spinning: false });
    }
  };

  updateOptions = () => {
    const { addedPanels, panels } = this.state;
    if (panels && panels.length > 0) {
      const panelsExcl =
        panels.filter(i => addedPanels.every(j => j.groupCode !== i.panelName)) || [];
      this.setState({ panelsExcl });
    }
  };

  handleGroupDelete = g => {
    const { addedPanels } = this.state;
    const newData = addedPanels.filter(i => i.groupCode !== g.groupCode);
    this.setState({ addedPanels: newData }, () => {
      this.updateOptions();
    });
  };

  handleModalClear = () => {
    this.setState({ pOrGValue: null, inputValue: '', addedPanels: [], err: '' }, () => {
      this.updateOptions();
    });
  };

  handleInputChange = e => {
    if (this.state.err !== '') {
      this.setState({ err: '' });
    }
    this.setState({ inputValue: e.target.value });
  };

  handlePOrGValueChange = (v, o) => {
    if (v) {
      this.setState({ pOrGValue: v, sOption: o });
    } else {
      this.setState({ pOrGValue: null });
    }
  };

  handleModalCancel = () => {
    this.handleModalClear();
    this.props.onCancel();
  };

  checkPanelNameDup = () => {
    const { inputValue } = this.state;
    const { menuGroups } = this.props.menu;
    const { multiPanelSelected } = this.props;
    let arr;
    if (multiPanelSelected) {
      arr = menuGroups.filter(i => i.key !== multiPanelSelected.key);
    } else {
      arr = menuGroups;
    }
    const bool =
      arr.filter(i => i.description.toLowerCase() === inputValue.toLowerCase()).length === 0;
    return bool;
  };

  addPanelItems = () => {
    const { addedPanels, inputValue } = this.state;
    if (addedPanels.length === 0 || !inputValue) return;
    const { panels } = this.state;
    let itemsArr = [];
    addedPanels.map(i =>
      panels.map(j => {
        if (j.panelName === i.description) {
          itemsArr = itemsArr.concat(j.panelItems);
        }
        return j;
      }),
    );

    let itemsAdded = [];
    itemsArr.map(i => {
      let obj = {};
      obj.catCode = inputValue;
      obj.categoryName = '';
      obj.itemSource = 2;
      obj.description = '';
      obj.shortDescription = i.shortDescription
        ? i.shortDescription
        : i.onlineItemInfo && i.onlineItemInfo.oconfig && i.onlineItemInfo.oconfig.shortDescription
        ? i.onlineItemInfo.oconfig.shortDescription
        : '';
      obj.inPackagePrice = i.priceInPackage;
      obj.index = 0;
      obj.ingredients = i.ingredients;
      obj.itemCategoryCode = i.itemCategoryCode;
      obj.itemCategoryName = i.itemCategoryName;
      obj.itemCode = i.itemCode;
      obj.itemName = i.description;
      obj.itemSizes = i.itemSizes;
      obj.itemTypeName = i.itemTypeName;
      obj.itemtype = i.itemtype;
      if (i.pizzaItems.length > 0) {
        obj.modifiers = i.pizzaItems;
      } else {
        obj.modifiers = i.modifiers;
      }
      obj.pizzaItems = i.pizzaItems;
      obj.price = i.price;
      obj.selected = false;

      const itemExists = itemsAdded.filter(o => o.itemCode === i.itemCode);
      if (itemExists.length === 0) {
        itemsAdded.push(obj);
      }
      return i;
    });
    this.props.receiveItems(itemsAdded);
  };

  addPanel = () => {
    const { addedPanels, inputValue } = this.state;
    const { multiPanelSelected } = this.props;
    if (!!multiPanelSelected) {
      this.props.delMenuGroup(multiPanelSelected);
      this.props.delMenuItems(multiPanelSelected);
    }
    this.addPanelItems();
    if (addedPanels.length > 0) {
      const obj = {};
      obj.itemSource = 2;
      obj.groupCode = inputValue;
      obj.description = inputValue;
      obj.onlineDescription = '';
      obj.subList = addedPanels;
      obj.key = multiPanelSelected && multiPanelSelected.key ? multiPanelSelected.key : nanoid();
      this.props.receiveMenuGroup(obj);
    }
    this.handleModalClear();
  };

  handleModalOK = () => {
    const { inputValue, addedPanels } = this.state;
    if (inputValue.trim() === '') {
      this.setState({ err: "Panel name mustn't be empty!" });
      return;
    }
    if (addedPanels.length === 0) {
      this.setState({ err: 'Panel List is empty!' });
      return;
    }
    const bool = this.checkPanelNameDup();
    if (bool) {
      this.addPanel();
    } else {
      this.setState({ err: 'Panel name is a duplicate!' });
      return;
    }
    this.props.onOK();
  };

  handleAddSubPanel = () => {
    const { sOption, pOrGValue, addedPanels } = this.state;
    const obj = {};
    if (pOrGValue) {
      obj.groupCode = sOption.children;
      obj.description = sOption.children;
      obj.itemSource = 0;
      obj.onlineDescription = '';
      obj.subList = [];
      this.setState({ addedPanels: [...addedPanels, obj], pOrGValue: null }, () => {
        this.updateOptions();
      });
    }
    if (this.state.err === 'Panel List is empty!') {
      this.setState({ err: '' });
    }
  };

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps, prevState) {
    const { multiPanelSelected } = this.props;
    if (multiPanelSelected !== prevProps.multiPanelSelected) {
      this.init();
    }
  }

  render() {
    const { pOrGValue, inputValue, err, addedPanels, panelsExcl, spinning } = this.state;
    const { visible } = this.props;

    const data = panelsExcl
      .sort((a, b) => a.panelName.localeCompare(b.panelName))
      .map(i => (
        <Option key={i.panelName} value={i.panelName}>
          {i.panelName}
        </Option>
      ));

    return (
      <Modal
        visible={visible}
        closable={false}
        width={600}
        maskClosable={false}
        onCancel={this.handleModalCancel}
        onOk={this.handleModalOK}
        destroyOnClose={true}
        title={
          <Row justify="space-between">
            <Col>
              <div>Add Multiple Panels</div>
            </Col>
            <Col>
              <Error errormsg={err} />
            </Col>
            {/* <Col>
              <Typography.Link style={{ fontWeight: 'normal' }} onClick={this.handleModalClear}>
                Clear
              </Typography.Link>
            </Col> */}
          </Row>
        }
      >
        <Spin spinning={spinning}>
          <Row justify="center">
            <div style={{ position: 'relative', width: 330, margin: 10 }}>
              <Input
                // disabled={multiPanelSelected}
                placeholder="Enter Panel Name"
                value={inputValue}
                onChange={e => {
                  this.handleInputChange(e);
                }}
              />
              <div className="multi-pg-input-primary"></div>
            </div>
          </Row>
          <Row justify="center">
            <Col>
              <Selection
                width={250}
                required={true}
                showSearch={true}
                placeholder={'Select Panel'}
                allowClear={true}
                data={data}
                value={pOrGValue}
                onChange={(v, o) => this.handlePOrGValueChange(v, o)}
              />
            </Col>
            <Col>
              <Button type="primary" style={{ margin: 10 }} onClick={this.handleAddSubPanel}>
                Add
              </Button>
            </Col>
          </Row>
          <Divider style={{ borderColor: '#fff' }} />
          <Table
            rowKey="groupCode"
            columns={this.columns}
            dataSource={addedPanels}
            pagination={false}
          />
        </Spin>
      </Modal>
    );
  }
}

export default connect(state => ({ menu: state.menu }), {
  receiveItems,
  receiveMenuGroup,
  delMenuItems,
  delMenuGroup,
})(MultiPGModal);
