import React, { Component } from 'react';
import { connect } from 'react-redux';
import { receiveError, initEditMenu } from '../../redux/actions';
import { Tabs, Divider, Spin, message, Modal, Select, Row, Input } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { reqItems, reqMenuList, reqSaveMenu, reqUploadMenu, reqPanels, reqGroups } from '../../api';
import Error from '../../components/Error';
import Selection from '../../components/Selection';
import MenuConfig from '../../components/MenuConfig';
import StoreHours from '../../components/StoreHours';
import SpecialHours from '../../components/SpecialHours';
import TaxMapping from '../../components/TaxMapping';
import PaymentMapping from '../../components/PaymentMapping';
import ProfitCenterMapping from '../../components/ProfitCMapping';
import PanelGroup from '../../components/PanelGroup';
import MenuItem from '../../components/MenuItems';
import UploadStoreList from '../../components/UploadStoreList';
import OrckCatMapping from '../../components/OrckCatMapping';
import OrckTasks from '../../components/OrckTasks';
import storageUtil from '../../util/storeUtil';
import './index.css';

const { TabPane } = Tabs;
const { Option } = Select;

class Menu extends Component {
  state = {
    menu: null,
    items: [],
    panels: [],
    groups: [],
    showTabs: false,
    btnText: '',
    uploadBtnText: '',
    uploadBtnDisabled: true,
    spinning: false,
    activeTab: 'store_hours',
    visible: false,
    modalStoreValue: null,
    modalMenuCode: null,
    modalMenuName: null,
    modalSpinning: false,
    dupMenuModalVisible: false,
    targetStoreMenuList: [],
    copyMenuModalError: '',
  };

  init = async () => {
    const { isNew } = this.props.location.state;
    const { menu } = this.props;
    if (menu.entId === '' && menu.storeNumber === '') {
      this.props.history.replace('/console');
    } else {
      this.setState({ spinning: true });
      const req = {};
      req.EnterpriseId = storageUtil.getEnt();
      req.StoreNumber = storageUtil.getStore();
      const resultItems = await reqItems(req);
      if (resultItems.status === 200 && resultItems.data) {
        this.setState({ items: resultItems.data });
      }
      const resultPanels = await reqPanels(req);
      if (resultPanels.status === 200 && resultPanels.data) {
        const panels = resultPanels.data.filter(
          i => i.panelType !== 1 && i.panelType !== 2 && i.panelType !== 5,
        );
        this.setState({ panels });
      }
      const resultGroups = await reqGroups(req);
      if (resultGroups.status === 200 && resultGroups.data) {
        this.setState({ groups: resultGroups.data });
      }
      this.setState({ spinning: false });

      if (!isNew) {
        this.setState({
          btnText: 'Save',
          uploadBtnText: 'Upload',
          showTabs: true,
        });
      } else {
        this.setState({ btnText: 'Create', uploadBtnText: 'Upload' });
      }
    }
  };

  handleSpinning = flag => {
    this.setState({ spinning: flag });
  };

  handleTabClick = key => {
    const { activeTab } = this.state;
    const { error } = this.props;
    if (activeTab !== key && error !== '') {
      message.warn('Please correct the entry (or entries)!');
      return;
    }
    this.setState({ activeTab: key });
  };

  handleBtnClick = v => {
    if (v === 'Create') {
      this.setState({ showTabs: true, btnText: 'Save' });
    }
  };

  handleUploadBtnClick = async () => {
    this.setState({ spinning: true });
    const enterpriseId = storageUtil.getEnt();
    const storeNumber = storageUtil.getStore();
    const { menuId } = this.props.menu;
    const data = {};
    data.headers = { enterpriseId, storeNumber, menuId };
    const re = await reqUploadMenu(data);
    if (re.status === 200) {
      const req = {};
      req.EnterpriseId = storageUtil.getEnt();
      req.StoreNumber = storageUtil.getStore();
      const re = await reqMenuList(req);
      if (re.status === 200 && re.data) {
        const newMenu = re.data.find(i => i.menuId === menuId);
        if (newMenu) {
          this.props.initEditMenu(newMenu);
        }
        this.setState({ spinning: false });
      } else if (re === 'Server Error') {
        this.setState({ spinning: false });
      }
      message.success('Menu successfully uploaded!', 2);
    } else if (re === 'Server Error') {
      this.setState({ spinning: false });
    }
  };

  handleCopyMenuModalOpen = () => {
    this.setState({ visible: true });
  };

  handleModalClose = () => {
    this.setState({
      visible: false,
      modalStoreValue: null,
      modalMenuCode: null,
      modalMenuName: null,
      copyMenuModalError: '',
    });
  };

  handleDelPartnerChange = (v, o) => {
    const { activeTab } = this.state;
    if (
      (o.children !== 'ORCKESTRA' && activeTab === 'orck_cat_Mapping') ||
      (o.children !== 'FLYT' && activeTab === 'upload_stores')
    ) {
      this.setState({ activeTab: 'store_hours' });
    }
  };

  handleModalStoreChange = async (v, o) => {
    this.setState({ modalStoreValue: v });
    if (v !== null) {
      this.setState({ modalSpinning: true, copyMenuModalError: '' });
      const req = {};
      req.EnterpriseId = storageUtil.getEnt();
      req.StoreNumber = v;
      const re = await reqMenuList(req);
      if (re.status === 200 && re.data) {
        const { data } = re;
        this.setState({ targetStoreMenuList: data || [] });
      }
      this.setState({ modalSpinning: false });
    }
  };

  handleModalMenuCodeChange = e => {
    if (e.target.value) {
      this.setState({ copyMenuModalError: '' });
    }
    this.setState({ modalMenuCode: e.target.value || '' });
  };

  handleModalMenuNameChange = e => {
    if (e.target.value) {
      this.setState({ copyMenuModalError: '' });
    }
    this.setState({ modalMenuName: e.target.value || '' });
  };

  handleDupModalClose = () => {
    this.copyMenu();
    this.setState({ dupMenuModalVisible: false });
    this.handleModalClose();
  };

  handleModalRemoveDuplicateMenuCode = () => {
    this.setState({ modalMenuCode: null, dupMenuModalVisible: false });
  };

  copyMenu = async () => {
    const { menu } = this.props;
    const { modalStoreValue, modalMenuName, modalMenuCode } = this.state;
    this.setState({ spinning: true, visible: false });
    const { items } = menu;
    let arr = JSON.parse(JSON.stringify(items));
    arr.map(i => {
      if (i.price === '') {
        i.price = 0;
      } else {
        i.price = i.price * 1;
      }
      i.modifiers.map(j => {
        if (j.fmax === '') {
          j.fmax = 0;
        } else {
          j.fmax = j.fmax * 1;
        }
        if (j.fmin === '') {
          j.fmin = 0;
        } else {
          j.fmin = j.fmin * 1;
        }
        j.modifierItems.map(k => {
          if (k.price === '') {
            k.price = 0;
          } else {
            k.price = k.price * 1;
          }
          return k;
        });
        return j;
      });
      return i;
    });

    const obj = {};
    obj.description = menu.description;
    obj.entId = menu.entId;
    obj.isPickupDeliveryMenu = menu.isPickupDeliveryMenu;
    obj.items = arr;
    obj.lastUploadedDateTime = '';
    obj.menuGroups = menu.menuGroups;
    obj.menuId = modalMenuCode;
    obj.menuName = modalMenuName;
    obj.menuType = menu.menuType;
    obj.openHours = menu.openHours;
    obj.otherinfo = {
      orchestraCategoryMappings: menu.orchestraCategoryMappings,
      pmtinfo: menu.pmtinfo,
      profitcentreMappings: menu.profitcentreMappings,
      taxmap: menu.taxmap,
    };
    obj.partnerType = menu.partnerType;
    obj.specialHours = menu.specialHours;
    obj.status = 0;
    obj.storeListForUpload = {
      storeList: menu.storeList,
    };
    obj.storeNumber = modalStoreValue;
    obj.thirdPartyId = menu.thirdPartyId;

    const re = await reqSaveMenu(obj);
    if (re.status === 200 && re.data) {
      message.success('Menu Successfully copied to selected store!');
      this.setState({ spinning: false });
    } else if (re === 'Server Error') {
      this.setState({ spinning: false });
    }
  };

  handleCopyMenu = () => {
    const { modalStoreValue, modalMenuName, modalMenuCode } = this.state;
    if (!modalStoreValue) {
      return this.setState({ copyMenuModalError: 'Please select a store!' });
    }
    if (!modalMenuCode) {
      return this.setState({ copyMenuModalError: 'Please enter a menu code!' });
    }
    if (!modalMenuName) {
      return this.setState({ copyMenuModalError: 'Please enter a menu name!' });
    }

    const { targetStoreMenuList } = this.state;
    const isDup =
      targetStoreMenuList.filter(i => i.menuId.toLowerCase() === modalMenuCode.toLowerCase())
        .length > 0;
    if (isDup) {
      this.setState({ dupMenuModalVisible: true });
    } else {
      this.copyMenu();
    }
  };

  componentDidMount() {
    this.init();
  }

  render() {
    // console.log(this.props.menu);
    const {
      btnText,
      showTabs,
      uploadBtnText,
      spinning,
      items,
      activeTab,
      visible,
      modalStoreValue,
      modalMenuCode,
      modalMenuName,
      modalSpinning,
      dupMenuModalVisible,
      copyMenuModalError,
      panels,
      groups,
    } = this.state;

    const { isNew } = this.props.location.state;
    const { ents } = this.props.location.state || [];
    const { stores } = this.props.location.state || [];

    const entName = ents.find(i => i.enterpriseId === storageUtil.getEnt()).description || '';
    const storeName = stores.find(i => i.storeNumber === storageUtil.getStore()).storeName || '';
    const otherStores = stores.filter(i => i.storeNumber !== storageUtil.getStore());

    const {
      menu: { partnerType },
    } = this.props;

    const storesOptions = otherStores.map(i => (
      <Option key={i.storeNumber} value={i.storeNumber}>
        {i.storeName}
      </Option>
    ));

    return (
      <Spin spinning={spinning}>
        <MenuConfig
          btnText={btnText}
          uploadBtnText={uploadBtnText}
          // uploadBtnDisabled={uploadBtnDisabled}
          isNew={isNew}
          entName={entName}
          storeName={storeName}
          onDelPartnerChange={(v, o) => this.handleDelPartnerChange(v, o)}
          onSpinning={value => {
            this.handleSpinning(value);
          }}
          onBtnClick={value => {
            this.handleBtnClick(value);
          }}
          onUploadBtnClick={value => {
            this.handleUploadBtnClick(value);
          }}
          onCopyMenuBtnClick={this.handleCopyMenuModalOpen}
        />
        <Divider style={{ borderColor: '#fff' }} />
        {!showTabs ? (
          <></>
        ) : (
          <Tabs
            size="large"
            type="card"
            tabPosition="left"
            tabBarStyle={{
              wordWrap: 'normal',
              fontWeight: '600',
              width: 180,
            }}
            tabBarGutter={8}
            activeKey={activeTab}
            onTabClick={key => this.handleTabClick(key)}
          >
            <TabPane tab="Store Hours" key="store_hours">
              <StoreHours />
            </TabPane>
            <TabPane tab="Special Hours" key="special_hours">
              <SpecialHours />
            </TabPane>
            <TabPane tab="Tax Mapping" key="tax_mapping">
              <TaxMapping />
            </TabPane>
            <TabPane tab="Payment Mapping" key="payment_mapping">
              <PaymentMapping />
            </TabPane>
            <TabPane
              tab={
                <div>
                  Profit Center <br /> Mapping
                </div>
              }
              key="proc_mapping"
            >
              <ProfitCenterMapping />
            </TabPane>
            <TabPane tab="Panel/Group" key="panel_group">
              <PanelGroup itemList={items} panels={panels} groups={groups} />
            </TabPane>
            <TabPane tab="Update Items" key="update_items">
              <MenuItem itemList={items} panels={panels} groups={groups} />
            </TabPane>
            {partnerType === 2 ? (
              <TabPane tab="Upload Stores" key="upload_stores">
                <UploadStoreList entStores={otherStores} />
              </TabPane>
            ) : (
              <></>
            )}
            {partnerType === 6 ? (
              <TabPane
                tab={
                  <div>
                    Orckestra Category <br /> Mapping
                  </div>
                }
                key="orck_cat_Mapping"
              >
                <OrckCatMapping />
              </TabPane>
            ) : (
              <></>
            )}
            {partnerType === 6 ? (
              <TabPane tab="Orckestra Tasks" key="orck_tasks">
                <OrckTasks />
              </TabPane>
            ) : (
              <></>
            )}
          </Tabs>
        )}
        <Modal
          title={
            <>
              <div>Copy Menu to Another Store</div>
              <Error
                errormsg={copyMenuModalError}
                style={{ height: '20px', textAlign: 'center' }}
              />
            </>
          }
          visible={visible}
          closable={false}
          maskClosable={false}
          onCancel={this.handleModalClose}
          onOk={this.handleCopyMenu}
        >
          <Spin spinning={modalSpinning}>
            <Row justify="center">
              <Selection
                toolTip="Store"
                showSearch
                width={250}
                required={true}
                value={modalStoreValue}
                data={storesOptions}
                placeholder="Select a store"
                onChange={(value, option) => {
                  this.handleModalStoreChange(value, option);
                }}
              />
            </Row>
            <Row justify="center">
              <div style={{ position: 'relative' }}>
                <Input
                  className="menu-modal-input-primary"
                  style={{ width: 250, margin: 10 }}
                  addonBefore="Menu Code"
                  value={modalMenuCode}
                  onChange={e => {
                    this.handleModalMenuCodeChange(e);
                  }}
                />
              </div>
            </Row>
            <Row justify="center">
              <div style={{ position: 'relative' }}>
                <Input
                  className="menu-modal-input-primary"
                  style={{ width: 250, margin: 10 }}
                  addonBefore="Menu Name"
                  value={modalMenuName}
                  onChange={e => {
                    this.handleModalMenuNameChange(e);
                  }}
                />
              </div>
            </Row>
          </Spin>
        </Modal>
        <Modal
          closable={false}
          maskClosable={false}
          visible={dupMenuModalVisible}
          width={550}
          okText={<span>Yes, I intend to replace an existing menu</span>}
          cancelText={<span>No, let me enter a different one</span>}
          onCancel={this.handleModalRemoveDuplicateMenuCode}
          onOk={this.handleDupModalClose}
          title={
            <div>
              <ExclamationCircleOutlined style={{ color: '#faad14' }} /> &nbsp; Duplicate Menu Code
            </div>
          }
        >
          <p>There is an existing menu with the same menu code.</p>
          <p>Do you intend to replace the existing menu?</p>
        </Modal>
      </Spin>
    );
  }
}
export default connect(state => ({ error: state.error, menu: state.menu }), {
  receiveError,
  initEditMenu,
})(Menu);
