import React, { Component } from 'react';
import { Select, Tooltip } from 'antd';
import './index.css';

export default class Selection extends Component {
  render() {
    const {
      width,
      placeholder,
      allowClear,
      showSearch,
      onChange,
      value,
      data,
      loading,
      disabled,
      required,
      toolTip,
    } = this.props;

    const style = {
      position: 'relative',
      width,
      margin: 10,
    };

    return (
      <Tooltip title={toolTip}>
        <Select
          className={required ? 'selection-primary' : ''}
          style={style}
          allowClear={allowClear}
          showSearch={showSearch}
          placeholder={placeholder}
          loading={loading}
          disabled={disabled}
          filterOption={(input, option) => {
            if (option.children) {
              return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ? true : false;
            } else if (option.label) {
              return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ? true : false;
            }
          }}
          value={value}
          onChange={(value, option) => onChange(value, option)}
        >
          {data}
        </Select>
      </Tooltip>
    );
  }
}
