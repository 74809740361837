import React, { Component } from 'react';
import './index.css';

export default class VTButton extends Component {
  render() {
    const { text = 'New Menu', onClick } = this.props;
    return (
      <button {...this.props} className="vt-button" onClick={() => onClick(text)}>
        {text}
      </button>
    );
  }
}
