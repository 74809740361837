import { Select } from 'antd';
const { Option } = Select;

export const deliPartners = [
  {
    id: 1,
    pName: 'UBER',
  },
  {
    id: 2,
    pName: 'FLYT',
  },
  {
    id: 3,
    pName: 'SKIP_THE_DISHES',
  },
  {
    id: 4,
    pName: 'DOOR_DASH',
  },
  {
    id: 5,
    pName: 'CHECK_MATE',
  },
  {
    id: 6,
    pName: 'ORCKESTRA',
  },
];

export const deliPartnerOptions = deliPartners.map(i => (
  <Option key={i.id} value={i.id}>
    {i.pName}
  </Option>
));
