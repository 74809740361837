import store from 'store';

const USER_KEY = 'user_key';
const ENT_KEY ='ent_key';
const STORE_KEY = 'store_key';

const storageUtil = {
  saveUser(user) {
    store.set(USER_KEY, user);
  },

  getUser() {
    return store.get(USER_KEY) || {};
  },

  removeUser() {
    store.remove(USER_KEY);
  },

  saveEnt(ent){
    store.set(ENT_KEY, ent);
  },

  getEnt(){
    return store.get(ENT_KEY) || '';
  },

  removeEnt(){
    store.remove(ENT_KEY)
  },

  saveStore(storeId){
    store.set(STORE_KEY, storeId);
  },

  getStore(){
    return store.get(STORE_KEY) || '';
  },

  removeStore(){
    store.remove(STORE_KEY)
  },

};

export default storageUtil;
