import axios from 'axios';
import { message } from 'antd';
import storageUtil from '../util/storeUtil';

//Intercept each request to add token to the request header
axios.interceptors.request.use(
  config => {
    const token = storageUtil.getUser().apiToken;
    token && (config.headers.Authorization = token);
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

export default function ajax(url, data = {}, headers = {}, type = 'GET') {
  return new Promise((resolve, reject) => {
    let promise;

    switch (type) {
      case 'GET':
        promise = axios.get(url, { headers: data });
        break;
      case 'POST':
        promise = axios.post(url, data, headers);
        break;
      case 'PUT':
        promise = axios.put(url, data, headers);
        break;
      case 'DELETE':
        promise = axios.delete(url);
        break;
      default:
        break;
    }

    promise
      .then(response => {
        resolve(response);
      })
      .catch(err => {
        //Return a new promise that resolves an error message
        if (err.response.data && err.response.data.message === 'Invalid UserName or Password') {
          message.error(err.response.data.message, 3);
          return new Promise((resolve, reject)=>resolve('Login Error')).then(response=>{resolve(response)});
        }else if ((err.response.data && err.response.data.message === 'Sorry, unble to get cloud data') || (err.response.data && err.response.data.message === 'Sorry, unable to get cloud data')) {
          message.error(err.response.data.message, 3);
          return new Promise((resolve, reject)=>resolve('No Cloud Data')).then(response=>{resolve(response)});
        }else if (err.response.data && err.response.data.message === 'Unauthorized') {
          storageUtil.removeUser();
          storageUtil.removeEnt();
          storageUtil.removeStore();
          message.error('Please log in.', 2);
          setTimeout(() => {
            window.location.href = '/login';
          }, 1000);
        } else {
          if (err.response.data && err.response.data.message) {
            message.error('Server Error', 3);
            return new Promise((resolve, reject)=>resolve('Server Error')).then(response=>{resolve(response)});
          }
          return new Promise((resolve, reject)=>resolve('Server Error')).then(response=>{resolve(response)});
        }
        // console.log(err.response);
      });
  });
}
