import React, { Component } from 'react';
import { Row, Col, Input, Tooltip, Modal, message, Typography } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  receiveError,
  receiveDeliveryparty,
  receiveMenuType,
  receiveMenuCode,
  receiveMenuName,
  receiveMenuDesc,
  initNewMenu,
  initEditMenu,
} from '../../redux/actions';
import VTButton from '../VTButton';
import Selection from '../Selection';
import { deliPartnerOptions } from '../../config/partnerId';
import { menuType, menuTypeOrchestra } from '../../config/menuType';
import { reqSaveMenu, reqDelMenu, reqMenuList, reqOrckInventorySync } from '../../api';
import storageUtil from '../../util/storeUtil';
import { lastUploadedDateTime } from '../../util/date';
import './index.css';
import { nanoid } from '@reduxjs/toolkit';

const { confirm } = Modal;

const { TextArea } = Input;

class MenuConfig extends Component {
  state = {
    menu: null,
    partner: null,
    menuTypeValue: null,
    menuCode: null,
    menuName: null,
    menuDesc: null,
    isPickupDeliveryMenu: null,
    visible: false,
    isReplacing: false,
    menuCodeDisabled: false,
    saved: false,
  };

  init = async () => {
    const { menu, isNew } = this.props;
    const { saved } = this.state;
    if (!!menu && Object.keys(menu).length > 0) {
      this.setState({
        menu,
        partner: menu.partnerType,
        menuTypeValue: menu.menuType,
        menuCode: menu.menuId,
        menuName: menu.menuName,
        menuDesc: menu.description,
        isPickupDeliveryMenu: menu.isPickupDeliveryMenu,
      });
    }
    if (isNew && !saved) {
      this.setState({ menuCodeDisabled: false });
    } else if (!isNew || saved) {
      this.setState({ menuCodeDisabled: true });
    }
  };

  handleDeliPartyChange = value => {
    const { menuTypeValue } = this.state;
    if (value) {
      if (this.props.error !== '') {
        this.props.receiveError('');
      }
      this.setState({ partner: value });
    }
    if (menuTypeValue !== null) {
      this.setState({ menuTypeValue: null });
    }
    this.props.receiveDeliveryparty(value);
  };

  handleMenuTypeChange = value => {
    if (value || value === 0 || value === 'Both') {
      if (this.props.error !== '') {
        this.props.receiveError('');
      }
      this.setState({ menuTypeValue: value });
    }
    this.props.receiveMenuType(value);
  };

  handleMenuCodeChange = e => {
    if (e.target.value) {
      if (this.props.error !== '') {
        this.props.receiveError('');
      }
    }
    this.setState({ menuCode: e.target.value.trim() });
    this.props.receiveMenuCode(e.target.value.trim());
  };

  handleMenuNameChange = e => {
    if (e.target.value) {
      if (this.props.error !== '') {
        this.props.receiveError('');
      }
    }
    this.setState({ menuName: e.target.value });
    this.props.receiveMenuName(e.target.value.trim());
  };

  handleDescChange = e => {
    this.setState({ menuDesc: e.target.value });
    this.props.receiveMenuDesc(e.target.value.trim());
  };

  checkNewMenuPre = () => {
    const { partner, menuTypeValue, menuCode, menuName } = this.state;
    if (!partner) {
      this.props.receiveError('Please select a delivery party!');
      return false;
    }
    if (
      menuTypeValue === null ||
      (menuTypeValue !== 3 &&
        menuTypeValue !== 2 &&
        menuTypeValue !== 1 &&
        menuTypeValue !== 0 &&
        menuTypeValue !== 'Both')
    ) {
      this.props.receiveError('Please select menu type!');
      return false;
    }
    if (!menuCode) {
      this.props.receiveError('Please enter menu code!');
      return false;
    }
    if (!menuName) {
      this.props.receiveError('Please enter menu name!');
      return false;
    }
    this.props.receiveError('');
    return true;
  };

  checkMenuCodeDup = async () => {
    this.props.onSpinning(true);
    const { menuCode } = this.state;
    const req = {};
    req.EnterpriseId = storageUtil.getEnt();
    req.StoreNumber = storageUtil.getStore();
    const re = await reqMenuList(req);
    if (re.status === 200 && re.data) {
      this.props.onSpinning(false);
      const hasDuplicate =
        re.data.filter(i => i.menuId.toLowerCase() === menuCode.toLowerCase()).length === 0;
      if (!hasDuplicate) {
        this.setState({ visible: true });
        return false;
      }
    } else if (re === 'Server Error') {
      this.props.onSpinning(false);
      return false;
    }
    return true;
  };

  handleBtnClick = async value => {
    if (value === 'Create') {
      if (this.checkNewMenuPre() && (await this.checkMenuCodeDup())) {
        this.setState({ menuCodeDisabled: true });
        this.props.onBtnClick('Create');
      }
    }
    if (value === 'Save') {
      if (this.checkNewMenuPre()) {
        this.saveMenu();
        this.props.onBtnClick('Save');
      }
    }
  };

  saveMenu = async () => {
    const { menu, isNew } = this.props;
    this.props.onSpinning(true);
    const { items } = menu;
    let arr = JSON.parse(JSON.stringify(items));
    arr.map(i => {
      if (i.price === '') {
        i.price = 0;
      } else {
        i.price = i.price * 1;
      }
      i.modifiers.map(j => {
        if (j.fmax === '') {
          j.fmax = 0;
        } else {
          j.fmax = j.fmax * 1;
        }
        if (j.fmin === '') {
          j.fmin = 0;
        } else {
          j.fmin = j.fmin * 1;
        }
        j.modifierItems.map(k => {
          if (k.price === '') {
            k.price = 0;
          } else {
            k.price = k.price * 1;
          }
          return k;
        });
        return j;
      });
      return i;
    });

    const obj = {};
    obj.description = menu.description;
    obj.entId = menu.entId;
    if (!isNew) {
      obj.id = menu.id;
    }
    obj.isPickupDeliveryMenu = menu.isPickupDeliveryMenu;
    obj.items = arr;
    obj.lastUploadedDateTime = menu.lastUploadedDateTime;
    obj.menuGroups = menu.menuGroups;
    obj.menuId = menu.menuId;
    obj.menuName = menu.menuName;
    obj.menuType = menu.menuType;
    obj.openHours = menu.openHours;
    obj.otherinfo = {
      orchestraCategoryMappings: menu.orchestraCategoryMappings,
      pmtinfo: menu.pmtinfo,
      profitcentreMappings: menu.profitcentreMappings,
      taxmap: menu.taxmap,
    };
    obj.partnerType = menu.partnerType;
    obj.specialHours = menu.specialHours;
    obj.status = menu.status;
    obj.storeListForUpload = {
      storeList: menu.storeList,
    };
    obj.storeNumber = menu.storeNumber;
    obj.thirdPartyId = menu.thirdPartyId;

    const re = await reqSaveMenu(obj);
    if (re.status === 200 && re.data) {
      const saved = re.data.menuId === menu.menuId;
      if (saved) {
        message.success('Menu successfully saved!');
      }
      this.setState({ saved });
      this.props.onSpinning(false);
      re.data.menuGroups = re.data.menuGroups.map(i => {
        i.key = nanoid();
        return i;
      });
      this.props.initEditMenu(re.data);
      this.init();
    } else if (re === 'Server Error') {
      this.props.onSpinning(false);
      this.init();
    }
  };

  handleDelMenu = () => {
    const { id } = this.props.menu;
    confirm({
      title: 'Sure to delete this menu?',
      icon: <ExclamationCircleOutlined />,
      content: '',

      onOk: async () => {
        this.props.onSpinning(false);
        const re = await reqDelMenu(id);
        if (re.status === 200) {
          message.success('Menu is deleted!');
          this.props.onSpinning(false);
          this.props.history.replace('/console');
        } else if (re === 'Server Error') {
          this.props.onSpinning(false);
        }
      },

      onCancel: () => {},
    });
  };

  //Chose to replace menu and close modal
  handleModalClose = () => {
    this.setState({ visible: false });
    this.setState({ menuCodeDisabled: true });
    this.props.onBtnClick('Create');
  };

  handleRemoveDuplicateMenuCode = () => {
    this.setState({ visible: false, menuCode: '' });
  };

  handleOrckSyncInventory = async () => {
    this.props.onSpinning(true);
    const enterpriseId = storageUtil.getEnt();
    const storeNumber = storageUtil.getStore();
    const data = {};
    data.headers = { enterpriseId, storeNumber };
    const re = await reqOrckInventorySync(data);
    if (re.status === 200) {
      this.props.onSpinning(false);
      message.success('Inventory successfully synched!', 2);
    } else if (re === 'Server Error' || re.status !== 200) {
      this.props.onSpinning(false);
    }
  };

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps, prevState) {
    const { isNew } = this.props;
    const { saved } = this.state;
    if (prevProps.isNew !== isNew || prevState.saved !== saved) {
      this.init();
    }
  }

  render() {
    const {
      partner,
      menuTypeValue,
      menuCode,
      menuName,
      menuDesc,
      isPickupDeliveryMenu,
      visible,
      menuCodeDisabled,
    } = this.state;

    const {
      btnText,
      uploadBtnText,
      // uploadBtnDisabled,
      onUploadBtnClick,
      onCopyMenuBtnClick,
      entName,
      storeName,
      error,
      onDelPartnerChange,
    } = this.props;

    return (
      <>
        <Row style={{ backgroundColor: '#e4f2ff', padding: 20 }}>
          <Col xs={{ span: 24 }} md={{ span: 8 }}>
            <Row style={{ height: 52 }} justify="center">
              <div style={{ margin: 10 }}>
                <span style={{ fontWeight: 'bold' }}>Enterprise:&nbsp;</span>
                <span>{entName}</span>
              </div>
            </Row>
            <Row style={{ height: 52 }} justify="center">
              <div style={{ margin: 10 }}>
                <span style={{ fontWeight: 'bold' }}>Store:&nbsp;</span>
                <span>{storeName}</span>
              </div>
            </Row>
            <Row style={{ height: 52 }} justify="center">
              {this.props.menu.id ? (
                <Typography.Link
                  type="primary"
                  disabled={
                    !menuCodeDisabled ||
                    error !== '' ||
                    partner === null ||
                    menuTypeValue === null ||
                    menuName === ''
                  }
                  style={{ margin: 10 }}
                  onClick={() => onCopyMenuBtnClick()}
                >
                  Copy Menu to Another Store
                </Typography.Link>
              ) : (
                <></>
              )}
            </Row>
            <Row style={{ height: 52 }} justify="space-around">
              {this.props.menu.id ? (
                <Typography.Link onClick={this.handleDelMenu} style={{ margin: 10 }}>
                  Delete Menu
                </Typography.Link>
              ) : (
                <></>
              )}
              {this.props.menu.partnerType === 6 && menuCodeDisabled && this.props.menu.id ? (
                <VTButton
                  text="Sync Inventory"
                  style={{ width: 130, height: 35, margin: '0 20px 10px 0' }}
                  onClick={this.handleOrckSyncInventory}
                />
              ) : (
                <></>
              )}
            </Row>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 8 }}>
            <Row justify="center">
              <Selection
                toolTip="Delivery Party"
                width={400}
                required={true}
                disabled={menuCodeDisabled}
                data={deliPartnerOptions}
                placeholder="Select a Delivery Party"
                value={partner}
                onChange={(value, option) => {
                  this.handleDeliPartyChange(value, option);
                  onDelPartnerChange(value, option);
                }}
              />
            </Row>
            <Row justify="center">
              <Selection
                toolTip="Menu Type"
                width={400}
                required={true}
                placeholder="Select Menu Type"
                data={partner !== 6 ? menuType : menuTypeOrchestra}
                value={isPickupDeliveryMenu === 1 ? 'Both' : menuTypeValue}
                onChange={(value, option) => {
                  this.handleMenuTypeChange(value, option);
                }}
              />
            </Row>
            <Row justify="center">
              <div style={{ position: 'relative', width: 400, margin: 10 }}>
                <Input
                  className="input-primary"
                  addonBefore="Menu Code"
                  disabled={menuCodeDisabled}
                  value={menuCode}
                  onChange={e => {
                    this.handleMenuCodeChange(e);
                  }}
                />
              </div>
            </Row>
            <Row style={{ position: 'relative' }} justify="center">
              <div style={{ position: 'relative', width: 400, margin: 10 }}>
                <Input
                  className="input-primary"
                  addonBefore="Menu Name"
                  value={menuName}
                  onChange={e => {
                    this.handleMenuNameChange(e);
                  }}
                />
              </div>
            </Row>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 8 }}>
            <Row style={{ marginTop: 10 }} justify="center">
              <Tooltip title="Menu Description">
                <TextArea
                  style={{ width: 300 }}
                  autoSize={{ minRows: 3, maxRows: 3 }}
                  showCount
                  placeholder="Menu Description"
                  maxLength={30}
                  value={menuDesc}
                  onChange={e => {
                    this.handleDescChange(e);
                  }}
                />
              </Tooltip>
            </Row>
            <Row
              justify="center"
              style={{
                marginBottom: 5,
                visibility: menuCodeDisabled && btnText === 'Save' ? 'visible' : 'hidden',
              }}
            >
              {partner && partner !== 6 ? (
                <span className="menu-config-warning-upload-not-available">
                  Uploading is not available at the moment.
                </span>
              ) : (
                <span className="menu-config-warning">* Save before uploading!</span>
              )}
            </Row>
            <Row justify="center">
              <VTButton
                text={uploadBtnText}
                disabled={
                  !menuCodeDisabled ||
                  error !== '' ||
                  partner === null ||
                  partner !== 6 ||
                  menuTypeValue === null ||
                  menuName === ''
                }
                style={{ width: 100, height: 35, margin: '0 20px 10px 0' }}
                onClick={() => onUploadBtnClick()}
              />
              <VTButton
                text={btnText}
                disabled={error !== ''}
                style={{ width: 100, height: 35, margin: '0 20px 10px 0' }}
                onClick={() => this.handleBtnClick(btnText)}
              />
            </Row>
            <Row
              justify="center"
              style={{
                visibility:
                  this.props.menu.status === 1 && this.props.menu.lastUploadedDateTime
                    ? 'visible'
                    : 'hidden',
              }}
            >
              <span style={{ color: '#888' }}>
                Last uploaded: {lastUploadedDateTime(this.props.menu.lastUploadedDateTime || '')}
              </span>
            </Row>
          </Col>
        </Row>
        <Modal
          closable={false}
          maskClosable={false}
          visible={visible}
          width={550}
          okText={<span>Yes, I intend to replace an existing menu</span>}
          cancelText={<span>No, let me enter a different one</span>}
          onCancel={this.handleRemoveDuplicateMenuCode}
          onOk={this.handleModalClose}
          title={
            <div>
              <ExclamationCircleOutlined style={{ color: '#faad14' }} /> &nbsp; Duplicate Menu Code
            </div>
          }
        >
          <p>There is an existing menu with the same menu code.</p>
          <p>Do you intend to replace the existing menu?</p>
        </Modal>
      </>
    );
  }
}
export default connect(state => ({ error: state.error, menu: state.menu }), {
  receiveError,
  receiveDeliveryparty,
  receiveMenuType,
  receiveMenuCode,
  receiveMenuName,
  receiveMenuDesc,
  initNewMenu,
  initEditMenu,
})(withRouter(MenuConfig));
