import React, { Component } from 'react';
import { Modal, Row, Button, Col } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import EditableTable from '../EditableTable';
import { connect } from 'react-redux';
import { updateMenuItemModItems } from '../../redux/actions';

class ModsItemsModal extends Component {
  state = { mod: {}, visible: null, dataSource: [], checkedArr: [], modSize: '' };

  init = () => {
    const { mod, visible, item } = this.props;
    const { items } = this.props.menu;
    const rItem = items.find(i => i.catCode === item.catCode && i.itemCode === item.itemCode);
    const { modifiers } = rItem;
    const { modifierItems } = modifiers.find(i => i.index === mod.index) || [];
    const modSize = this.getSize(mod.size);

    const checkedArr = [];
    if (mod && modifierItems) {
      let arr = JSON.parse(JSON.stringify(modifierItems));
      arr.map(i => {
        if (i.selected) {
          checkedArr.push(i.itemCode);
        }
        i.price = parseFloat(i.price.toString()).toFixed(2);
        return i;
      });
      this.setState({ mod, visible, dataSource: arr, checkedArr, modSize });
    }
  };

  getSize = size => {
    const { item } = this.props;
    if (item) {
      const { itemSizes } = item;
      const sizeArr = itemSizes.filter(i => i.code === size);
      if (sizeArr && sizeArr.length > 0) {
        const sizeObj = sizeArr[0];
        return sizeObj.name;
      }
    }
  };

  handleSelectionChange = (keys, rows) => {
    this.setState({ checkedArr: keys });
    const { item, mod } = this.props;
    const { items } = this.props.menu;
    const rItem = items.find(i => i.catCode === item.catCode && i.itemCode === item.itemCode);
    const { modifiers } = rItem;
    const { modifierItems } = modifiers.find(i => i.index === mod.index) || [];
    let arr = JSON.parse(JSON.stringify(modifierItems));
    arr.map(i => {
      if (keys.indexOf(i.itemCode) >= 0) {
        i.selected = true;
      } else {
        i.selected = false;
      }
      i.price = parseFloat(i.price.toString()).toFixed(2);
      return i;
    });
    const obj = {};
    obj.item = item;
    obj.mod = mod;
    obj.modItems = arr;
    this.props.updateMenuItemModItems(obj);
  };

  getUpdatedModsItemsData = data => {
    const { item, mod } = this.props;
    const { checkedArr } = this.state;
    data.map(i => {
      if (checkedArr.indexOf(i.itemCode) >= 0) {
        i.selected = true;
      } else {
        i.selected = false;
      }
      i.price = parseFloat(i.price.toString() === '' ? 0 : i.price.toString()).toFixed(2);
      return i;
    });
    const obj = {};
    obj.item = item;
    obj.mod = mod;
    obj.modItems = data;
    this.props.updateMenuItemModItems(obj);
  };

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps, prevState) {
    const { mod, visible, item } = this.props;
    if (prevState.mod !== mod || prevProps.visible !== visible || prevProps.item !== item) {
      this.init();
    }
  }

  render() {
    const { mod, visible, dataSource, checkedArr, modSize } = this.state;
    const { closable, zIndex, onCancel, onOk, itemCode, itemName } = this.props;
    const { groupName } = mod;

    const columns = [
      {
        title: 'Item Code',
        dataIndex: 'itemCode',
      },
      {
        title: 'Item Description',
        dataIndex: 'description',
        sorter: (a, b) => a.description.localeCompare(b.description),
      },
      {
        title: () => (
          <Row justify="space-between" align="middle">
            <span>Price </span>
            <EditOutlined style={{ color: '#a5a5a5', marginRight: 2 }} />
          </Row>
        ),
        dataIndex: 'price',
        editable: true,
      },
    ];

    return (
      <Modal
        closable={closable}
        zIndex={zIndex}
        maskClosable={false}
        visible={visible}
        onCancel={onCancel}
        onOk={onOk}
        title={
          <Row justify="space-between" align="middle">
            <Col>
              {modSize ? (
                <>
                  <span style={{ color: '#999' }}>{itemCode + ' ' + itemName + ' > '}</span>
                  <span>{groupName + ' size ' + modSize}</span>
                </>
              ) : (
                <>
                  <span style={{ color: '#999' }}>{itemCode + ' ' + itemName + ' > '}</span>
                  <span>{groupName}</span>
                </>
              )}
            </Col>
            <Col>
              {dataSource.length > 20 ? (
                <Button type="primary" onClick={onOk}>
                  Close
                </Button>
              ) : (
                <></>
              )}
            </Col>
          </Row>
        }
        footer={[
          <Button key="0" type="primary" onClick={onOk}>
            Close
          </Button>,
        ]}
      >
        <EditableTable
          size="small"
          rowKey="itemCode"
          rowSelection={{
            type: 'Checkbox',
            selectedRowKeys: checkedArr,
            onChange: (keys, rows) => this.handleSelectionChange(keys, rows),
          }}
          tableColumns={columns}
          tableDataSource={dataSource}
          pagination={false}
          // pOrG="modItems"
          itemSize={false}
          itemMods={false}
          getUpdatedData={updatedData => this.getUpdatedModsItemsData(updatedData)}
        />
      </Modal>
    );
  }
}
export default connect(
  state => ({
    menu: state.menu,
  }),
  { updateMenuItemModItems },
)(ModsItemsModal);
