export const orckTaskStatus = [
    {
        statusCode: 0,
        status: "Created"
    },
    {
        statusCode: 1,
        status: "WaitingToRun"
    },
    {
        statusCode: 2,
        status: "Running"
    },
    {
        statusCode: 3,
        status: "RanToCompletion"
    },
    {
        statusCode: 4,
        status: "Canceled"
    },
    {
        statusCode: 5,
        status: "Faulted"
    },
    {
        statusCode: 6,
        status: "Idle"
    },
    {
        statusCode: 7,
        status: "WaitingToCancel"
    },
    {
        statusCode: 8,
        status: "Ignored"
    },
    {
        statusCode: 9,
        status: "QueuedForSequence"
    },
    {
        statusCode: 10,
        status: "IdleAfterErrorRecovery"
    },
    {
        statusCode: 11,
        status: "WaitingToRetry"
    },
    {
        statusCode: 12,
        status: "IdleAfterFault"
    },
]