import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Radio, Spin, Select, Divider, Typography } from 'antd';
import Selection from '../Selection';
import PanelGroupTable from '../PanelGroupTable';
import MultiPGModal from '../MultiPGModal';
import VTButton from '../VTButton';
// import { reqPanels, reqGroups } from '../../api';
// import storageUtil from '../../util/storeUtil';
import { receiveMenuGroup, receiveItems, delMenuGroup, delMenuItems } from '../../redux/actions';
import { nanoid } from '@reduxjs/toolkit';

const { Option } = Select;

class PanelGroup extends Component {
  state = {
    panelOrGroup: 'Panel',
    pOrGValue: null,
    sOption: null,
    // panels: [],
    panelExcl: [],
    // groups: [],
    groupExcl: [],
    items: [],
    loading: false,
    multiPGModalVisible: false,
    multiPanelSelected: null,
  };

  panelOrGroup = [
    { label: 'Panel', value: 'Panel' },
    { label: 'Group', value: 'Group' },
  ];

  init = () => {
    const { menuGroups } = this.props.menu;
    // const { panels, groups } = this.state;
    const { panels, groups } = this.props;
    const panelExcl = panels.filter(i =>
      menuGroups.filter(i => i.itemSource === 0).every(j => j.groupCode !== i.panelName),
    );
    const groupExcl = groups.filter(i =>
      menuGroups.filter(i => i.itemSource === 1).every(j => j.groupCode !== i.gpCode),
    );
    this.setState({ panelExcl, groupExcl, pOrGValue: null });
  };

  // getData = async data => {
  //   this.setState({ loading: true });

  //   let arr = [];
  //   const resultPanels = await reqPanels(data);
  //   if (resultPanels.status === 200 && resultPanels.data) {
  //     arr = resultPanels.data.filter(
  //       i => i.panelType !== 1 && i.panelType !== 2 && i.panelType !== 5,
  //     );
  //     this.setState({ panels: arr });
  //   }
  //   const resultGroups = await reqGroups(data);
  //   if (resultGroups.status === 200 && resultGroups.data) {
  //     this.setState({ groups: resultGroups.data });
  //   }

  //   this.setState({ loading: false });

  //   const { menuGroups } = this.props.menu;
  //   const panelExcl = arr.filter(i =>
  //     menuGroups.filter(i => i.itemSource === 0).every(j => j.groupCode !== i.panelName),
  //   );
  //   const groupExcl = resultGroups.data.filter(i =>
  //     menuGroups.filter(i => i.itemSource === 1).every(j => j.groupCode !== i.gpCode),
  //   );
  //   this.setState({ panelExcl, groupExcl, pOrGValue: null });
  // };

  handleSelectionClear = () => {
    this.setState({ panelOrGroup: 'Panel', pOrGValue: null });
  };

  handlePGSelectChange = e => {
    const { pOrGValue } = this.state;
    if (pOrGValue !== null) {
      this.setState({ pOrGValue: null });
    }
    this.setState({ panelOrGroup: e.target.value });
  };

  handlePOrGValueChange = (v, o) => {
    if (v) {
      this.setState({ pOrGValue: v, sOption: o });
    } else {
      this.setState({ pOrGValue: null });
    }
  };

  AddGroup = (panelOrGroup, pOrGValue, sOption) => {
    if (!pOrGValue || !sOption) return;
    const { panels, itemList } = this.props;
    let itemsArr = [];
    if (panelOrGroup === 'Panel') {
      const panel = panels.filter(i => i.panelName === pOrGValue);
      itemsArr = panel[0].panelItems;
    }
    if (panelOrGroup === 'Group') {
      itemsArr = itemList.filter(i => i.gpCode === pOrGValue);
    }
    const itemsAdded = [];
    itemsArr.map(i => {
      let obj = {};
      obj.catCode = pOrGValue;
      if (panelOrGroup === 'Panel') {
        obj.categoryName = '';
        obj.itemSource = 0;
      } else {
        obj.categoryName = sOption.children;
        obj.itemSource = 1;
      }
      obj.description = '';
      obj.shortDescription = i.shortDescription
        ? i.shortDescription
        : i.onlineItemInfo && i.onlineItemInfo.oconfig && i.onlineItemInfo.oconfig.shortDescription
        ? i.onlineItemInfo.oconfig.shortDescription
        : '';
      obj.inPackagePrice = i.priceInPackage;
      obj.index = 0;
      obj.ingredients = i.ingredients;
      obj.itemCategoryCode = i.itemCategoryCode;
      obj.itemCategoryName = i.itemCategoryName;
      obj.itemCode = i.itemCode;
      obj.itemName = i.description;
      obj.itemSizes = i.itemSizes;
      obj.itemTypeName = i.itemTypeName;
      obj.itemtype = i.itemtype;
      if (i.pizzaItems.length > 0) {
        obj.modifiers = i.pizzaItems;
      } else {
        obj.modifiers = i.modifiers;
      }
      obj.pizzaItems = i.pizzaItems;
      obj.price = i.price;
      obj.selected = false;
      itemsAdded.push(obj);
      return i;
    });
    this.props.receiveItems(itemsAdded);
  };

  handleAddGroupBtn = () => {
    const { panelOrGroup, pOrGValue, sOption } = this.state;
    this.AddGroup(panelOrGroup, pOrGValue, sOption);
    if (pOrGValue && sOption) {
      const obj = {};
      if (panelOrGroup === 'Panel') {
        obj.itemSource = 0;
      } else {
        obj.itemSource = 1;
      }
      obj.groupCode = pOrGValue;
      obj.description = sOption.children;
      obj.onlineDescription = '';
      obj.subList = [];
      obj.key = nanoid();
      this.props.receiveMenuGroup(obj);
      this.init();
    }
  };

  handleMultiPGModalOpen = () => {
    this.setState({ multiPGModalVisible: true });
  };

  handleMultiPGModalCancel = () => {
    this.setState({ multiPanelSelected: null, multiPGModalVisible: false });
  };
  handleMultiPGModalOK = () => {
    this.setState({ multiPGModalVisible: false, multiPanelSelected: null });
  };

  handleEditPanel = row => {
    // console.log(row);
    if (row.itemSource === 2) {
      this.setState({ multiPanelSelected: row }, () => {
        this.setState({ multiPGModalVisible: true });
      });
    }
  };

  componentDidMount() {
    // const req = {};
    // req.EnterpriseId = storageUtil.getEnt();
    // req.StoreNumber = storageUtil.getStore();
    // this.getData(req);
    this.init();
  }

  componentDidUpdate(prevProps, prevState) {
    const { menuGroups } = this.props.menu;
    if (prevProps.menu.menuGroups !== menuGroups) {
      this.init();
    }
  }

  render() {
    const {
      panelOrGroup,
      pOrGValue,
      panelExcl,
      groupExcl,
      loading,
      multiPGModalVisible,
      multiPanelSelected,
    } = this.state;
    const { menuGroups } = this.props.menu;
    const { panels, groups } = this.props;

    let dataExcl = [];
    if (panels && panelOrGroup === 'Panel') {
      dataExcl = panelExcl
        .sort((a, b) => a.panelName.localeCompare(b.panelName))
        .map(i => (
          <Option key={i.panelName} value={i.panelName}>
            {i.panelName}
          </Option>
        ));
    } else if (groups && panelOrGroup === 'Group') {
      dataExcl = groupExcl
        .sort((a, b) => a.description.localeCompare(b.description))
        .map(i => (
          <Option key={i.gpCode} value={i.gpCode}>
            {i.description}
          </Option>
        ));
    }

    return (
      <Spin spinning={loading}>
        <div style={{ backgroundColor: '#e4f2ff', padding: '20px' }}>
          <Row align="middle">
            <Col>
              <div style={{ margin: 10 }} onClick={this.handleSelectionClear}>
                <Typography.Link>Clear</Typography.Link>
              </div>
            </Col>
            <Col>
              <Radio.Group
                style={{ margin: 10 }}
                options={this.panelOrGroup}
                onChange={e => {
                  this.handlePGSelectChange(e);
                }}
                value={panelOrGroup}
                optionType="button"
                buttonStyle="solid"
              />
            </Col>
            <Col>
              <Selection
                width={250}
                showSearch={true}
                placeholder={'Select ' + panelOrGroup}
                data={dataExcl}
                loading={loading}
                value={pOrGValue}
                onChange={(v, o) => this.handlePOrGValueChange(v, o)}
              />
            </Col>
            <Col>
              <VTButton
                text="Add"
                style={{ width: 100, height: 35, margin: '10px 10px 15px 10px' }}
                onClick={this.handleAddGroupBtn}
              />
            </Col>
            <Divider type="vertical" style={{ borderColor: '#999' }} />
            <Col>
              <VTButton
                text="Add Multiple Panels"
                style={{ width: 180, height: 35, margin: '10px 10px 15px 10px' }}
                onClick={this.handleMultiPGModalOpen}
              />
            </Col>
          </Row>
        </div>
        <Divider style={{ borderColor: '#fff', marginBottom: '0' }} />
        <PanelGroupTable
          spinning={loading}
          panelOrGroup={panelOrGroup}
          pOrGValue={pOrGValue}
          menuGroups={menuGroups}
          onEditPanel={row => {
            this.handleEditPanel(row);
          }}
        />
        <MultiPGModal
          visible={multiPGModalVisible}
          onCancel={this.handleMultiPGModalCancel}
          onOK={this.handleMultiPGModalOK}
          multiPanelSelected={multiPanelSelected}
        />
      </Spin>
    );
  }
}
export default connect(
  state => ({
    error: state.error,
    menu: state.menu,
  }),
  { receiveMenuGroup, receiveItems, delMenuGroup, delMenuItems },
)(PanelGroup);
