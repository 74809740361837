export function capitalize (string){
    const capStr = string
    .split('/')[1]
    .slice(0, 1)
    .toUpperCase()
    .split('')
    .concat(string.split('/')[1].slice(1).split(''))
    .join('');

    return capStr;
}