import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Select } from 'antd';
import Selection from '../Selection';
import { reqTaxMap } from '../../api';
import { receiveTaxMap } from '../../redux/actions';
import storageUtil from '../../util/storeUtil';

const { Option } = Select;

class TaxMapping extends Component {
  state = { loading: false, list: [], selectedOption: null };

  init = async () => {
    this.setState({ loading: true });
    const req = {};
    req.EnterpriseId = storageUtil.getEnt();
    req.StoreNumber = storageUtil.getStore();
    const re = await reqTaxMap(req);
    const { taxmap } = this.props.menu;
    if (re.status === 200 && re.data) {
      this.setState({ loading: false, list: re.data });
      const item = re.data.find(i => i.code === taxmap.code);
      if (item && item.code) {
        this.setState({ selectedOption: item.code });
      }
    } else if (re === 'Server Error') {
      this.setState({ loading: false });
    }
  };

  handleTaxMappingChange = v => {
    const { list } = this.state;
    let item;
    if (v === null || v === undefined) {
      item = {};
    } else {
      item = list.find(i => i.code === v);
    }
    this.setState({ selectedOption: v });
    this.props.receiveTaxMap(item);
  };

  componentDidMount() {
    this.init();
  }

  render() {
    const { loading, list, selectedOption } = this.state;
    const taxMapOptions = list.map(i => (
      <Option key={i.code} value={i.code}>
        {i.description}
      </Option>
    ));

    return (
      <Selection
        toolTip="Tax Mapping"
        width={220}
        data={taxMapOptions}
        value={selectedOption}
        loading={loading}
        allowClear={true}
        placeholder="Select Tax Mapping"
        onChange={(value, option) => this.handleTaxMappingChange(value, option)}
      />
    );
  }
}
export default connect(
  state => ({
    menu: state.menu,
  }),
  { receiveTaxMap },
)(TaxMapping);
