import { Form, Input, Table, message } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import './index.css';

const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      for (const key in values) {
        values[key] = values[key].trim();
      }
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      // console.log('Save failed:', errInfo);
      message.warning('Field not saved properly. Please check it again!');
    }
  };

  let childNode = children;
  if (editable) {
    let _title = '';
    switch (dataIndex) {
      case 'price':
        _title = 'Price';
        break;
      case 'fmax':
        _title = 'Max Qty';
        break;
      case 'fmin':
        _title = 'Min Qty';
        break;
      default:
        break;
    }

    let _pattern = '',
      _message = '';
    switch (_title) {
      case 'Price':
        _pattern = '^[0-9]+([.]{1}[0-9]{1,2})?$';
        _message = 'A number with up to 2 decimal places is accepted.';
        break;
      case 'Max Qty':
      case 'Min Qty':
        _pattern = '^d*[0-9]{1,}d*$';
        _message = 'An integer is accepted.';
        break;
      default:
        break;
    }

    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            pattern: _pattern,
            message: _message,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

//props: tableDataSource, tableColumns, size, rowKey, rowSelection, pagination, pOrG, itemSize, itemMods, scroll...
const EditableTable = props => {
  const [dataSource, setDataSource] = useState(props.tableDataSource);
  const handleSave = row => {
    const newData = [...dataSource];
    let index;
    if (!props.itemSize && !props.itemMods) {
      index = newData.findIndex(item => row.itemCode === item.itemCode);
    }
    if (!props.itemSize && !props.itemMods && props.oDes) {
      index = newData.findIndex(
        item => row.groupCode === item.groupCode && row.itemSource === item.itemSource,
      );
    }
    if (!props.itemSize && !props.itemMods && !props.oDes && props.pStore) {
      index = newData.findIndex(item => row.storeNumber === item.storeNumber);
    }
    if (props.itemSize && !props.itemMods) {
      index = newData.findIndex(item => row.code === item.code);
    }
    if (!props.itemSize && props.itemMods) {
      index = newData.findIndex(item => row.key === item.key);
    }
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });
    setDataSource(newData);
    props.getUpdatedData(newData);
  };

  useEffect(() => {
    setDataSource(props.tableDataSource);
  }, [props.tableDataSource]);

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = props.tableColumns.map(col => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: record => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });
  return (
    <Table
      components={components}
      size={props.size}
      rowKey={props.rowKey}
      rowSelection={props.rowSelection}
      rowClassName={() => 'editable-row'}
      scroll={props.scroll}
      bordered
      pagination={props.pagination}
      dataSource={dataSource}
      columns={columns}
    />
  );
};

export default EditableTable;
