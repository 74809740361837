import React, { Component } from 'react';
import { Row, Col, Popconfirm, Typography } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import EditableTable from '../EditableTable';
import { connect } from 'react-redux';
import { updateMenuGroup, delMenuGroup, delMenuItems } from '../../redux/actions';
import { nanoid } from 'nanoid';

class PanelGroupTable extends Component {
  getUpdatedOnlineDesData = data => {
    this.props.updateMenuGroup(data);
  };

  handleEditPanel = row => {
    this.props.onEditPanel(row);
  };

  columns = [
    {
      title: 'Panel/Group Name',
      dataIndex: 'description',
    },
    {
      title: () => (
        <Row justify="space-between" align="middle">
          <span>Online Description</span>
          <EditOutlined style={{ color: '#a5a5a5', marginRight: 2 }} />
        </Row>
      ),
      dataIndex: 'onlineDescription',
      editable: true,
    },
    {
      title: 'Actions',
      render: (text, record) => {
        return (
          <Row>
            <Col span={12}>
              {record.itemSource === 2 ? (
                <Typography.Link
                  onClick={() => {
                    this.handleEditPanel(record);
                  }}
                >
                  Edit
                </Typography.Link>
              ) : null}
            </Col>
            <Col span={12}>
              <Popconfirm title="Sure to delete?" onConfirm={() => this.handleGroupDelete(record)}>
                <Typography.Link>Delete</Typography.Link>
              </Popconfirm>
            </Col>
          </Row>
        );
      },
    },
  ];

  handleGroupDelete = group => {
    this.props.delMenuGroup(group);
    this.props.delMenuItems(group);
  };

  render() {
    const { menuGroups } = this.props.menu;

    return (
      <EditableTable
        size="middle"
        // rowKey="groupCode"
        rowKey={record => record.groupCode + nanoid()}
        tableColumns={this.columns}
        tableDataSource={menuGroups}
        pagination={false}
        itemSize={false}
        itemMods={false}
        oDes={true}
        getUpdatedData={updatedData => this.getUpdatedOnlineDesData(updatedData)}
      />
    );
  }
}
export default connect(
  state => ({
    menu: state.menu,
  }),
  { updateMenuGroup, delMenuGroup, delMenuItems },
)(PanelGroupTable);
