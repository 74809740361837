import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Form, Input, Row, Col, Spin, message, Checkbox } from 'antd';
import logo from '../../assets/logos/logo.webp';
import VTButton from '../../components/VTButton';
import { login, loginError } from '../../redux/actions';

class Login extends Component {
  state = {
    formInitValues: { username: '', rememberMe: true },
    spinning: false,
  };

  formRef = React.createRef();

  onFinish = async value => {
    this.setState({ spinning: true });
    const data = {};
    data.username = value.username;
    data.password = value.password;
    const { remember } = value || true;
    localStorage.setItem('rememberMe', remember);
    localStorage.setItem('username', remember ? value.username : '');
    await this.props.login(data);
    const { user } = this.props;
    if (user && user.apiToken) {
      this.props.loginError(false);
      this.props.history.replace('/');
      message.success('Successfully logged in!');
    } else if (this.props.error !== false && this.state.spinning !== false) {
      this.setState({ spinning: false });
    }
  };

  handleLoginButton = e => {
    if (e.target !== e.currentTarget) {
      return false;
    }
  };

  componentDidMount() {
    this.props.loginError(false);
    let username, rememberMe;
    if (localStorage.getItem('rememberMe') === null) {
      rememberMe = true;
    } else {
      rememberMe = localStorage.getItem('rememberMe') === 'true';
      username = rememberMe ? localStorage.getItem('username') : '';
    }
    this.formRef.current.setFieldsValue({
      username: username,
      remember: rememberMe,
    });
  }

  render() {
    const { spinning } = this.state;
    const { user } = this.props;
    if (user && user.apiToken) {
      return <Redirect to="/" />;
    }

    return (
      <Spin spinning={spinning}>
        <Row
          type="flex"
          justify="center"
          align="middle"
          style={{ minHeight: '100vh', padding: 18 }}
        >
          <Col>
            <div className="login-logo-wrap">
              <img src={logo} alt="Logo" />
            </div>
            <div
              className="login-page-title"
              style={{ fontFamily: '"Oxygen", serif', fontWeight: 'bold', fontSize: 20 }}
            >
              <h2>Visual Delivery Login</h2>
            </div>
            <Form
              name="basic"
              ref={this.formRef}
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 16,
              }}
              initialValues={this.state.formInitValues}
              onFinish={this.onFinish}
              autoComplete="off"
            >
              <Form.Item
                label="Username"
                name="username"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your username!',
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your password!',
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item
                name="remember"
                valuePropName="checked"
                wrapperCol={{
                  xs: {
                    offset: 0,
                    span: 24,
                  },
                  sm: { offset: 8, span: 16 },
                }}
              >
                <Checkbox>Remember me</Checkbox>
              </Form.Item>

              <Form.Item
                wrapperCol={{
                  xs: {
                    offset: 0,
                    span: 24,
                  },
                  sm: { offset: 8, span: 16 },
                }}
              >
                <VTButton
                  type="submit"
                  text="Log in"
                  style={{ width: '100%', height: 35, marginTop: 30 }}
                  onClick={this.handleLoginButton}
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Spin>
    );
  }
}

export default connect(
  state => ({
    error: state.loginError,
    user: state.user,
  }),
  { login, loginError },
)(Login);
