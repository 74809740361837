import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table } from 'antd';
import { receiveFlytUploadStoreList } from '../../redux/actions';

class UploadStoreList extends Component {
  state = { selectedStoreNumbers: [] };

  handleSelectionChange = (keys, rows) => {
    this.setState({ selectedStoreNumbers: keys });
    this.props.receiveFlytUploadStoreList(keys);
  };

  componentDidMount() {
    const {
      menu: { storeList },
    } = this.props;
    this.setState({ selectedStoreNumbers: storeList });
  }

  render() {
    const { selectedStoreNumbers } = this.state;
    const { entStores } = this.props;

    const columns = [
      {
        title: 'Store Name',
        dataIndex: 'storeName',
      },
    ];

    return (
      <Table
        size="small"
        rowKey="storeNumber"
        columns={columns}
        dataSource={entStores}
        pagination={false}
        rowSelection={{
          type: 'Checkbox',
          selectedRowKeys: selectedStoreNumbers,
          onChange: (keys, rows) => this.handleSelectionChange(keys, rows),
        }}
      />
    );
  }
}
export default connect(state => ({ error: state.error, menu: state.menu }), {
  receiveFlytUploadStoreList,
})(UploadStoreList);
