import React, { Component } from 'react';
import { Row, Col, Radio, Divider, Input, Select, Typography } from 'antd';
import { connect } from 'react-redux';
import { receiveItems, updateMenuItems } from '../../redux/actions';
import Selection from '../Selection';
import MenuItemsTable from '../MenuItemsTable';
import MenuItemSizeModal from '../MenuItemSizeModal';
import MenuItemModsModal from '../MenuItemModsModal';

const { Option } = Select;

class MenuItems extends Component {
  state = {
    panelOrGroup: 'Panel',
    pOrGValue: null,
    onlineDesc: '',
    showList: false,
    panelIncl: [],
    groupIncl: [],
    sizeModalOpen: false,
    modsModalOpen: false,
    item: {},
    isMPanel: false,
    showMsg: false,
  };

  panelOrGroup = [
    { label: 'Panel', value: 'Panel' },
    { label: 'Group', value: 'Group' },
  ];

  init = () => {
    this.updateOnlineDesc();
    const { menuGroups } = this.props.menu;
    const panelIncl = menuGroups.filter(i => i.itemSource === 0 || i.itemSource === 2);
    const groupIncl = menuGroups.filter(i => i.itemSource === 1);
    this.setState({ panelIncl, groupIncl, pOrGValue: null });
  };

  updateOnlineDesc = () => {
    const { menuGroups } = this.props.menu;
    const { pOrGValue } = this.state;
    if (pOrGValue) {
      const sGroup = menuGroups.find(i => i.groupCode === pOrGValue) || {};
      this.setState({ onlineDesc: sGroup.onlineDescription || '' });
    }
  };

  handleSelectionClear = () => {
    this.setState({ panelOrGroup: 'Panel', pOrGValue: null, showList: false, onlineDesc: null });
  };

  handlePGSelectChange = e => {
    const { pOrGValue, showList, onlineDesc } = this.state;
    if (pOrGValue !== null) {
      this.setState({ pOrGValue: null });
    }
    if (onlineDesc !== '') {
      this.setState({ onlineDesc: '' });
    }
    if (showList === true) {
      this.setState({ showList: false });
    }
    this.setState({ panelOrGroup: e.target.value });
  };

  handlePOrGValueChange = v => {
    const { panelOrGroup } = this.state;
    const { menuGroups, items: menuItems } = this.props.menu;
    const { panels, itemList } = this.props;

    if (v) {
      const sGroup =
        menuGroups.find(
          i =>
            i.groupCode === v &&
            (panelOrGroup === 'Group' ? i.itemSource === 1 : i.itemSource !== 1),
        ) || {};

      let newItems = [],
        panelItems = [],
        panelMenuItems = [],
        removedItems = [];
      if (panelOrGroup === 'Panel' && sGroup.itemSource === 0) {
        panelItems = panels.find(i => i.panelName === v).panelItems || [];
        panelMenuItems = menuItems.filter(i => i.catCode === v && i.itemSource === 0);
      }

      if (panelOrGroup === 'Panel' && sGroup.itemSource === 2) {
        this.setState({ isMPanel: true });
        const { subList } = sGroup || [];
        if (!!subList) {
          const mPanels = subList.map(i => panels.find(j => j.panelName === i.groupCode));
          panelItems = mPanels.reduce((c, n) => c.concat(n.panelItems), []);
          panelMenuItems = menuItems.filter(i => i.catCode === v && i.itemSource === 2);
        }
      } else {
        this.setState({ isMPanel: false });
      }

      if (panelOrGroup === 'Group' && sGroup.itemSource === 1) {
        panelItems = itemList.filter(i => i.gpCode === v);
        panelMenuItems = menuItems.filter(i => i.catCode === v && i.itemSource === 1);
      }

      newItems = panelItems.filter(i => panelMenuItems.every(j => j.itemCode !== i.itemCode)) || [];
      removedItems =
        panelMenuItems.filter(i => panelItems.every(j => j.itemCode !== i.itemCode)) || [];
      const cPanelMenuItems = JSON.parse(JSON.stringify(panelMenuItems));
      if (cPanelMenuItems && cPanelMenuItems.length > 0) {
        const updatedPanelMenuItems =
          cPanelMenuItems.filter(i => removedItems.every(j => j.itemCode !== i.itemCode)) || [];
        this.props.updateMenuItems(updatedPanelMenuItems);
      }

      if (newItems.length > 0) {
        const itemsAdded = [];
        newItems.map(i => {
          let obj = {};
          obj.catCode = v;
          if (panelOrGroup === 'Panel' && sGroup.itemSource === 0) {
            obj.categoryName = '';
            obj.itemSource = 0;
          } else if (panelOrGroup === 'Panel' && sGroup.itemSource === 2) {
            obj.categoryName = '';
            obj.itemSource = 2;
          } else {
            obj.categoryName = sGroup.description;
            obj.itemSource = 1;
          }
          obj.description = i.description ? i.description : '';
          obj.shortDescription = i.shortDescription
            ? i.shortDescription
            : i.onlineItemInfo &&
              i.onlineItemInfo.oconfig &&
              i.onlineItemInfo.oconfig.shortDescription
            ? i.onlineItemInfo.oconfig.shortDescription
            : '';
          obj.inPackagePrice = i.priceInPackage;
          obj.index = 0;
          obj.ingredients = i.ingredients;
          obj.itemCategoryCode = i.itemCategoryCode;
          obj.itemCategoryName = i.itemCategoryName;
          obj.itemCode = i.itemCode;
          obj.itemName = i.description;
          obj.itemSizes = i.itemSizes;
          obj.itemTypeName = i.itemTypeName;
          obj.itemtype = i.itemtype;
          if (i.pizzaItems.length > 0) {
            obj.modifiers = i.pizzaItems;
          } else {
            obj.modifiers = i.modifiers;
          }
          obj.pizzaItems = i.pizzaItems;
          obj.price = i.price;
          obj.selected = false;
          itemsAdded.push(obj);
          return i;
        });
        this.props.receiveItems(itemsAdded);
      }

      this.setState({ pOrGValue: v, showList: true, onlineDesc: sGroup.onlineDescription || '' });
    } else {
      this.setState({ pOrGValue: null, showList: false });
    }
  };

  handleSizeOpen = item => {
    const { items: menuItems } = this.props.menu;
    const obj = menuItems
      .filter(i => i.catCode === item.catCode)
      .find(i => i.itemCode === item.itemCode);
    this.setState({ item: obj });
    const { sizeModalOpen } = this.state;
    if (!sizeModalOpen) {
      this.setState({ sizeModalOpen: true });
    }
  };

  handleSizeCancel = () => {
    const { sizeModalOpen } = this.state;
    if (sizeModalOpen) {
      this.setState({ sizeModalOpen: false });
    }
  };

  handleModsOpen = item => {
    const { items: menuItems } = this.props.menu;
    const obj = menuItems
      .filter(i => i.catCode === item.catCode)
      .find(i => i.itemCode === item.itemCode);
    this.setState({ item: obj });
    const { modsModalOpen } = this.state;
    if (!modsModalOpen) {
      this.setState({ modsModalOpen: true });
    }
  };

  handleModsCancel = () => {
    const { modsModalOpen } = this.state;
    if (modsModalOpen) {
      this.setState({ modsModalOpen: false });
    }
  };

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps, prevState) {
    const { menuGroups } = this.props.menu;
    if (prevProps.menu.menuGroups !== menuGroups) {
      this.init();
    }
  }

  render() {
    const {
      panelOrGroup,
      pOrGValue,
      onlineDesc,
      panelIncl,
      groupIncl,
      showList,
      sizeModalOpen,
      modsModalOpen,
      item,
      isMPanel,
    } = this.state;

    const { itemList } = this.props;

    let dataIncl = [];
    if (panelOrGroup === 'Panel') {
      dataIncl = panelIncl.map(i => (
        <Option key={i.groupCode} value={i.groupCode}>
          {i.description}
        </Option>
      ));
    } else if (panelOrGroup === 'Group') {
      dataIncl = groupIncl.map(i => (
        <Option key={i.groupCode} value={i.groupCode}>
          {i.description}
        </Option>
      ));
    }

    return (
      <>
        <div style={{ backgroundColor: '#e4f2ff', padding: '20px' }}>
          <Row align="middle">
            <Col>
              <div style={{ margin: 10 }} onClick={this.handleSelectionClear}>
                <Typography.Link>Clear</Typography.Link>
              </div>
            </Col>
            <Col>
              <Radio.Group
                style={{ margin: 10 }}
                options={this.panelOrGroup}
                onChange={e => {
                  this.handlePGSelectChange(e);
                }}
                value={panelOrGroup}
                optionType="button"
                buttonStyle="solid"
              />
            </Col>
            <Col>
              <Selection
                width={250}
                showSearch={true}
                panelOrGroup={panelOrGroup}
                placeholder={'Select ' + panelOrGroup}
                data={dataIncl}
                value={pOrGValue}
                onChange={(v, o) => this.handlePOrGValueChange(v, o)}
              />
            </Col>
            <Col>
              <Input
                style={{ width: 400, margin: 10 }}
                addonBefore="Online Description"
                disabled={true}
                value={onlineDesc}
              />
            </Col>
            {/* <Col>
              <Row style={{ color: 'var(--primary)', fontWeight: 'bold' }}>
                * "Save" to update menu with your recent changes to panel/group
              </Row>
            </Col> */}
          </Row>
        </div>
        <Divider style={{ borderColor: '#fff', marginBottom: '0' }} />
        {showList ? (
          <MenuItemsTable
            panelOrGroup={panelOrGroup}
            pOrGValue={pOrGValue}
            itemList={itemList}
            isMPanel={isMPanel}
            onSizeClick={item => {
              this.handleSizeOpen(item);
            }}
            onModsClick={item => {
              this.handleModsOpen(item);
            }}
          />
        ) : (
          <></>
        )}
        <MenuItemSizeModal
          closable={false}
          visible={sizeModalOpen}
          item={item}
          pOrG={panelOrGroup}
          onSizeCancel={this.handleSizeCancel}
        />
        <MenuItemModsModal
          closable={false}
          visible={modsModalOpen}
          item={item}
          pOrG={panelOrGroup}
          onModsCancel={this.handleModsCancel}
        />
      </>
    );
  }
}
export default connect(
  state => ({
    error: state.error,
    menu: state.menu,
  }),
  { receiveItems, updateMenuItems },
)(MenuItems);
