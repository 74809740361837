import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Row, Col } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import EditableTable from '../EditableTable';
import ModsItemsModal from '../ModsItemsModal';
import Error from '../Error';
import { updateMenuItemMods, receiveError } from '../../redux/actions';

class MenuItemModsModal extends Component {
  state = {
    visible: null,
    mods: [],
    itemCode: '',
    itemName: '',
    mVisible: false,
    mod: {},
    okDisabled: false,
  };

  init = () => {
    const { visible, item } = this.props;
    this.props.receiveError('');
    let mods = [],
      itemName = '',
      itemCode = '';
    if (item && item.modifiers) {
      mods = JSON.parse(JSON.stringify(item.modifiers));
      mods = mods.map(i => {
        i.fmax = i.fmax ? i.fmax.toString() : '0';
        i.fmin = i.fmin ? i.fmin.toString() : '0';
        return i;
      });
      mods = mods.filter(i => i.modifierItems.length > 0);
      itemCode = item.itemCode;
      itemName = item.itemName;
    }
    this.setState({ visible, mods, itemCode, itemName });
  };

  getSize = size => {
    const { item } = this.props;
    if (item) {
      const { itemSizes } = item;
      const sizeArr = itemSizes.filter(i => i.code === size);
      if (sizeArr && sizeArr.length > 0) {
        const sizeObj = sizeArr[0];
        return sizeObj.name;
      }
    }
  };

  checkModsData = data => {
    const arr = data.filter(
      i => parseInt(i.fmin) > 0 && parseInt(i.fmax) > 0 && parseInt(i.fmax) < parseInt(i.fmin),
    );
    const arr2 = data.filter(i => i.fmax === '' || i.fmin === '');
    if (arr.length === 0 && arr2.length === 0) {
      this.props.receiveError('');
      this.setState({ okDisabled: false });
      return true;
    }
    if (arr.length > 0 || arr2.length > 0) {
      this.setState({ okDisabled: true });
      if (arr.length > 0) {
        this.props.receiveError("Max quantity mustn' t be smaller than min quantity!");
      }
      if (arr2.length > 0) {
        this.props.receiveError('Max quantity or min quantity cannot be empty!');
      }
      return false;
    }
  };

  getUpdatedModsData = data => {
    const bool = this.checkModsData(data);
    if (bool) {
      const { item } = this.props;
      const obj = {};
      obj.item = item;
      obj.mods = data;
      this.props.updateMenuItemMods(obj);
    }
  };

  handleSetupClick = mod => {
    const { mVisible } = this.state;
    this.setState({ mod });
    if (mVisible === false) {
      this.setState({ mVisible: true });
    }
  };

  handleMModalCancel = () => {
    const { mVisible } = this.state;
    if (mVisible === true) {
      this.setState({ mVisible: false });
    }
  };

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps, prevState) {
    const { visible, item } = this.props;
    if (prevState.visible !== visible || prevProps.item !== item) {
      this.init();
    }
  }

  render() {
    const { visible, mods, itemCode, itemName, mVisible, mod, okDisabled } = this.state;
    const { pOrG, onModsCancel, closable, item, error } = this.props;
    const columns = [
      {
        title: 'Group/Panel',
        render: (text, mod) => (
          <div>
            {mod.forcedType === 2
              ? '--MULTI_ITEM--'
              : mod.forcedType === 3
              ? mod.itemCode
              : mod.groupName}
          </div>
        ),
      },
      ...(item && item.pizzaItems && item.pizzaItems.length > 0
        ? [
            {
              title: 'Size',
              render: (text, mod) => {
                text = this.getSize(mod.size);
                return <div>{text}</div>;
              },
            },
          ]
        : []),
      {
        title: 'Title/Description',
        dataIndex: 'groupTitle',
      },
      {
        title: () => (
          <Row justify="space-between" align="middle">
            <span>Min Qty</span>
            <EditOutlined style={{ color: '#a5a5a5', marginRight: 2 }} />
          </Row>
        ),
        dataIndex: 'fmin',
        editable: true,
      },
      {
        title: () => (
          <Row justify="space-between" align="middle">
            <span>Max Qty</span>
            <EditOutlined style={{ color: '#a5a5a5', marginRight: 2 }} />
          </Row>
        ),
        dataIndex: 'fmax',
        editable: true,
      },
      {
        title: 'Price Info',
        render: (text, mod) => (
          <div style={{ textAlign: 'center' }}>
            <Button type="primary" size="small" onClick={() => this.handleSetupClick(mod)}>
              Setup
            </Button>
          </div>
        ),
      },
    ];

    return (
      <Modal
        closable={closable}
        maskClosable={false}
        zIndex={999}
        width={700}
        visible={visible}
        title={
          <Row justify="space-between" align="middle">
            <Col>
              <div>
                {itemCode}
                &nbsp;&nbsp;&nbsp;
                {itemName}
              </div>
            </Col>
            <Col>
              <Error errormsg={error} />
            </Col>
          </Row>
        }
        footer={[
          <Button key="0" type="primary" disabled={okDisabled} onClick={onModsCancel}>
            Close
          </Button>,
        ]}
      >
        {/* props: tableDataSource, tableColumns, size, rowKey, rowSelection, pagination */}
        <EditableTable
          size="small"
          rowKey="index"
          tableColumns={columns}
          tableDataSource={mods}
          pagination={false}
          pOrG={pOrG}
          itemSize={false}
          itemMods={true}
          getUpdatedData={updatedData => this.getUpdatedModsData(updatedData)}
        />
        <ModsItemsModal
          closable={false}
          maskClosable={false}
          item={item}
          itemCode={itemCode}
          itemName={itemName}
          zIndex={1000}
          visible={mVisible}
          mod={mod}
          onCancel={this.handleMModalCancel}
          onOk={this.handleMModalCancel}
        />
      </Modal>
    );
  }
}
export default connect(
  state => ({
    error: state.error,
    menu: state.menu,
  }),
  { updateMenuItemMods, receiveError },
)(MenuItemModsModal);
