import ajax from './ajax';

// const BASE = 'https://localhost:44371';
const BASE = 'https://vtdelivery.visualtouchonline.com';

export const reqLogin = data => ajax(BASE + '/users/clouduser', data, null, 'POST');
export const reqStores = data => ajax(BASE + `/users/stores/${data}`);
export const reqMenuList = data => ajax(BASE + '/users/menu', data);
export const reqTaxMap = data => ajax(BASE + '/users/taxmap', data);
export const reqPmtMap = data => ajax(BASE + '/users/pmtmap', data);
export const reqProfitCentres = data => ajax(BASE + '/users/profitcentre', data);
export const reqPanels = data => ajax(BASE + '/users/panel', data);
export const reqGroups = data => ajax(BASE + '/users/groups', data);
export const reqItems = data => ajax(BASE + '/users/items', data);
export const reqSaveMenu = data => ajax(BASE + '/users/menu', data, null, 'POST');
export const reqDelMenu = data => ajax(BASE + `/users/menu/${data}`, null, null, 'DELETE');
export const reqPartners = data => ajax(BASE + '/api/partner/v1', data);
export const reqUpdatePartner = data => ajax(BASE + '/api/partner/v1', data, null, 'POST');
export const reqDelPartner = data => ajax(BASE + `/api/partner/v1/${data}`, null, null, 'DELETE');
export const reqUploadMenu = data => ajax(BASE + '/users/menu/upload', {}, data, 'POST');
export const reqOrckTasks = data => ajax(BASE + '/api/orchestra/v1/tasks', data);
export const reqOrckTaskLogs = (id, data) => ajax(BASE + `/api/orchestra/v1/tasks/${id}/logs`, data );
export const reqOrckInventorySync = (data) => ajax(BASE + '/api/Orchestra/v1/inventory/auto', {}, data, 'POST' );
