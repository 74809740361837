import { combineReducers } from 'redux';
import storageUtil from '../util/storeUtil';
import {
  LOGIN_ERROR,
  RECEIVE_USER,
  RESET_USER,
  RECEIVE_ERROR,
  RECEIVE_STORE_HOURS,
  RECEIVE_SPECIAL_HOURS,
  DELETE_SPECIAL_HOURS,
  RECEIVE_TAX_MAP,
  RECEIVE_PMT_INFO,
  RECEIVE_PC_MAP,
  DELETE_PC_MAP,
  MENU_RECEIVE_GROUP,
  MENU_UPDATE_GROUP,
  MENU_DEL_GROUP,
  MENU_DEL_ITEMS,
  MENU_DEL_REMOVED_ITEMS,
  MENU_EDITINIT,
  MENU_RECEIVE_ITEMS,
  MENU_UPDATE_ITEMS,
  MENU_UPDATE_ITEM_SIZES,
  MENU_UPDATE_ITEM_MODS,
  MENU_UPDATE_ITEM_MOD_ITEMS,
  RECEIVE_MENU_DELIPARTY,
  RECEIVE_MENU_TYPE,
  RECEIVE_MENU_CODE,
  RECEIVE_MENU_NAME,
  RECEIVE_MENU_DESC,
  MENU_NEWINIT,
  RECEIVE_ORCK_CAT_MAP,
  DELETE_ORCK_CAT_MAP,
  RECEIVE_FLYT_UPLOAD_STORE_LIST,
  RECEIVE_PARTNERS,
  UPDATE_PARTNERS,
  ADD_PARTNER,
  RECEIVE_PARTNER_STORE,
  UPDATE_PARTNER_STORES,
  DELETE_PARTNER_STORE,
} from './action-types';

const initLoginError = false;
function loginError(state = initLoginError, action) {
  switch (action.type) {
    case LOGIN_ERROR:
      return action.data;
    default:
      return state;
  }
}

const initError = '';
function error(state = initError, action) {
  switch (action.type) {
    case RECEIVE_ERROR:
      return action.error;
    default:
      return state;
  }
}

const initUser = storageUtil.getUser();
function user(state = initUser, action) {
  switch (action.type) {
    case RECEIVE_USER:
      return action.user;
    case RESET_USER:
      return {};
    default:
      return state;
  }
}

const initMenu = {
  id: null,
  entId: '',
  storeNumber: '',
  isPickupDeliveryMenu: 0,
  description: '',
  items: [],
  lastUploadedDateTime: '',
  menuGroups: [],
  menuId: '',
  menuName: '',
  menuType: null,
  openHours: [],
  specialHours: [],
  partnerType: null,
  taxmap: {},
  pmtinfo: {},
  orchestraCategoryMappings: [],
  profitcentreMappings: [],
  status: null,
  storeList: [],
  thirdPartyId: '',
};

function menu(state = initMenu, action) {
  switch (action.type) {
    case MENU_NEWINIT: {
      return {
        ...state,
        id: null,
        entId: storageUtil.getEnt(),
        storeNumber: storageUtil.getStore(),
        isPickupDeliveryMenu: 0,
        description: '',
        items: [],
        lastUploadedDateTime: '',
        menuGroups: [],
        menuId: '',
        menuName: '',
        menuType: null,
        openHours: [],
        specialHours: [],
        partnerType: null,
        taxmap: {},
        pmtinfo: {},
        orchestraCategoryMappings: [],
        profitcentreMappings: [],
        status: 0,
        storeList: [],
        thirdPartyId: '',
      };
    }

    case MENU_EDITINIT:
      const eMenu = action.existingMenu;
      return {
        ...state,
        id: eMenu.id ? eMenu.id : 0,
        entId: eMenu.entId ? eMenu.entId : 0,
        storeNumber: eMenu.storeNumber ? eMenu.storeNumber : 0,
        isPickupDeliveryMenu: eMenu.isPickupDeliveryMenu ? eMenu.isPickupDeliveryMenu : 0,
        description: eMenu.description ? eMenu.description : '',
        items: eMenu.items ? eMenu.items : [],
        lastUploadedDateTime: eMenu.lastUploadedDateTime ? eMenu.lastUploadedDateTime : '',
        menuGroups: eMenu.menuGroups ? eMenu.menuGroups : [],
        menuId: eMenu.menuId ? eMenu.menuId : '',
        menuName: eMenu.menuName ? eMenu.menuName : '',
        menuType: eMenu.menuType ? eMenu.menuType : 0,
        openHours: eMenu.openHours ? eMenu.openHours : [],
        specialHours: eMenu.specialHours ? eMenu.specialHours : [],
        partnerType: eMenu.partnerType,
        taxmap: eMenu.otherinfo && eMenu.otherinfo.taxmap ? eMenu.otherinfo.taxmap : {},
        pmtinfo: eMenu.otherinfo && eMenu.otherinfo.pmtinfo ? eMenu.otherinfo.pmtinfo : {},
        orchestraCategoryMappings:
          eMenu.otherinfo && eMenu.otherinfo.orchestraCategoryMappings
            ? eMenu.otherinfo.orchestraCategoryMappings
            : [],
        profitcentreMappings:
          eMenu.otherinfo && eMenu.otherinfo.profitcentreMappings
            ? eMenu.otherinfo.profitcentreMappings
            : [],
        status: eMenu.status ? eMenu.status : 0,
        storeList:
          eMenu.storeListForUpload && eMenu.storeListForUpload.storeList
            ? eMenu.storeListForUpload.storeList
            : [],
        thirdPartyId: eMenu.thirdPartyId ? eMenu.thirdPartyId : '',
      };

    case MENU_RECEIVE_GROUP:
      const isExistingMenuGroup = state.menuGroups.filter(i=>i.key === action.data.key).length > 0;
      if (isExistingMenuGroup) {
        const gIndex = state.menuGroups.findIndex(
          i => i.key === action.data.key,
        );
        let head = gIndex <= 0 ? [] : state.menuGroups.slice(0, gIndex);
      let tail = state.menuGroups.slice(gIndex + 1);
      return {
        ...state,
        menuGroups: [...head, action.data, ...tail]
      }
      }else {
      return {
        ...state,
        menuGroups: [...state.menuGroups, action.data],
      }};

    case MENU_UPDATE_GROUP:
      return {
        ...state,
        menuGroups: [...action.data],
      };

    case MENU_DEL_GROUP:
      // const gIndex = state.menuGroups.findIndex(
      //   i=>i.key === action.data.key
      // );
      // let head = gIndex <= 0 ? [] : state.menuGroups.slice(0, gIndex);
      // let tail = state.menuGroups.slice(gIndex + 1);
      return {
        ...state,
        menuGroups: [...state.menuGroups.filter(i=>i.key !== action.data.key)],
      };

    case RECEIVE_STORE_HOURS:
      if (action.data.startTime === '' || action.data.endTime === '') {
        return {
          ...state,
          openHours: [...state.openHours.filter(i => i.dayIndex !== action.data.dayIndex)],
        };
      } else {
        return {
          ...state,
          openHours: [
            ...state.openHours.filter(i => i.dayIndex !== action.data.dayIndex),
            action.data,
          ],
        };
      }

    case RECEIVE_SPECIAL_HOURS:
      return {
        ...state,
        specialHours: [...action.data],
      };

    case DELETE_SPECIAL_HOURS:
      return {
        ...state,
        specialHours: [...state.specialHours.filter(i => i.date !== action.data.date)],
      };

    case RECEIVE_TAX_MAP:
      return {
        ...state,
        taxmap: action.data,
      };

    case RECEIVE_PMT_INFO:
      return {
        ...state,
        pmtinfo: action.data,
      };

    case RECEIVE_PC_MAP:
      return {
        ...state,
        profitcentreMappings: [...action.data],
      };

    case DELETE_PC_MAP:
      return {
        ...state,
        profitcentreMappings: [
          ...state.profitcentreMappings.filter(
            i =>
              i.orderType !== action.data.orderType &&
              i.profitCentreCode !== action.data.profitCentreCode,
          ),
        ],
      };

    case MENU_RECEIVE_ITEMS:
      return {
        ...state,
        items: [...state.items, ...action.data],
      };

    case MENU_UPDATE_ITEMS:
      return {
        ...state,
        items: [...state.items.filter(i => i.catCode !== action.data[0].catCode), ...action.data],
      };

    case MENU_DEL_ITEMS:
      return {
        ...state,
        items: [...state.items.filter(i => i.catCode !== action.data.groupCode)],
      };

    // case MENU_DEL_REMOVED_ITEMS:
    //   return {
    //     ...state,
    //     items: [
    //       ...state.items.filter(i =>
    //         action.data.every(j => i.itemCode !== j.itemCode),
    //       ),
    //     ],
    //   };

    case MENU_UPDATE_ITEM_SIZES:
      return {
        ...state,
        items: [
          ...state.items
            .filter(i => i.catCode !== action.data.item.catCode)
            .concat(
              state.items
                .filter(i => i.catCode === action.data.item.catCode)
                .filter(i => i.itemCode !== action.data.item.itemCode),
            ),
          {
            ...state.items
              .filter(i => i.catCode === action.data.item.catCode)
              .find(i => i.itemCode === action.data.item.itemCode),
            itemSizes: [...action.data.sizes],
          },
        ].sort((a, b) => a.itemName.localeCompare(b.itemName)),
      };

    case MENU_UPDATE_ITEM_MODS:
      return {
        ...state,
        items: [
          ...state.items
            .filter(i => i.catCode !== action.data.item.catCode)
            .concat(
              state.items
                .filter(i => i.catCode === action.data.item.catCode)
                .filter(i => i.itemCode !== action.data.item.itemCode),
            ),
          {
            ...state.items
              .filter(i => i.catCode === action.data.item.catCode)
              .find(i => i.itemCode === action.data.item.itemCode),
            modifiers: [...action.data.mods],
          },
        ].sort((a, b) => a.itemName.localeCompare(b.itemName)),
      };

    case MENU_UPDATE_ITEM_MOD_ITEMS:
      const item = state.items
        .filter(i => i.catCode === action.data.item.catCode)
        .find(i => i.itemCode === action.data.item.itemCode);
      return {
        ...state,
        items: [
          ...state.items
            .filter(i => i.catCode !== action.data.item.catCode)
            .concat(
              state.items
                .filter(i => i.catCode === action.data.item.catCode)
                .filter(i => i.itemCode !== action.data.item.itemCode),
            ),
          {
            ...item,
            modifiers: [
              ...item.modifiers.filter(i => i.index !== action.data.mod.index),
              {
                ...item.modifiers.find(i => i.index === action.data.mod.index),
                modifierItems: [...action.data.modItems],
              },
            ].sort((a, b) => a.index - b.index),
          },
        ].sort((a, b) => a.itemName.localeCompare(b.itemName)),
      };

    case RECEIVE_MENU_DELIPARTY:
      return {
        ...state,
        partnerType: action.data,
      };

    case RECEIVE_MENU_TYPE:
      if (action.data === 'Both') {
        return {
          ...state,
          isPickupDeliveryMenu: 1,
        };
      } else {
        return {
          ...state,
          menuType: action.data,
          isPickupDeliveryMenu: 0,
        };
      }

    case RECEIVE_MENU_CODE:
      return {
        ...state,
        menuId: action.data,
      };

    case RECEIVE_MENU_NAME:
      return {
        ...state,
        menuName: action.data,
      };

    case RECEIVE_MENU_DESC:
      return {
        ...state,
        description: action.data,
      };

    case RECEIVE_ORCK_CAT_MAP:
      return {
        ...state,
        orchestraCategoryMappings: [...action.data],
      };

    case DELETE_ORCK_CAT_MAP:
      return {
        ...state,
        orchestraCategoryMappings: [
          ...state.orchestraCategoryMappings.filter(
            i =>
              i.orchestraCategory !== action.data.orchestraCategory &&
              i.vtCategory !== action.data.vtCategory,
          ),
        ],
      };

    case RECEIVE_FLYT_UPLOAD_STORE_LIST:
      return {
        ...state,
        storeList: [...action.data],
      };

    default:
      return state;
  }
}

const initPartners = [];
function partners(state = initPartners, action) {
  switch (action.type) {
    case RECEIVE_PARTNERS:
      return action.data;

    case UPDATE_PARTNERS:
      const index = state.findIndex(i => i.partnerType === action.data.partnerType);
      let head = index <= 0 ? [] : state.slice(0, index);
      let tail = state.slice(index + 1);
      return [...head, action.data, ...tail];

    case ADD_PARTNER:
      return [...state, action.data];

    case RECEIVE_PARTNER_STORE:
      return [
        ...state.filter(i => i.partnerType !== action.data.partnerType),
        {
          ...state.find(i => i.partnerType === action.data.partnerType),
          partnerStores: [
            ...state.find(i => i.partnerType === action.data.partnerType).partnerStores,
            action.data,
          ],
        },
      ].sort((a, b) => a.partnerType - b.partnerType);

    case UPDATE_PARTNER_STORES:
      return [
        ...state.filter(i => i.partnerType !== action.data[0].partnerType),
        {
          ...state.find(i => i.partnerType === action.data[0].partnerType),
          partnerStores: [
            ...state
              .find(i => i.partnerType === action.data[0].partnerType)
              .partnerStores.filter(i =>
                action.data.every(
                  j => !(j.storeNumber === i.storeNumber && j.enterpriseId === i.enterpriseId),
                ),
              ),
            ...action.data,
          ],
        },
      ].sort((a, b) => a.partnerType - b.partnerType);

    case DELETE_PARTNER_STORE:
      return [
        ...state.filter(i => i.partnerType !== action.data.partnerType),
        {
          ...state.find(i => i.partnerType === action.data.partnerType),
          partnerStores: [
            ...state
              .find(i => i.partnerType === action.data.partnerType)
              .partnerStores.filter(
                i =>
                  !(
                    i.storeNumber === action.data.storeNumber &&
                    i.enterpriseId === action.data.enterpriseId
                  ),
              ),
          ],
        },
      ].sort((a, b) => a.partnerType - b.partnerType);

    default:
      return state;
  }
}

export default combineReducers({
  loginError,
  user,
  error,
  menu,
  partners,
});
