import {
  LOGIN_ERROR,
  RECEIVE_USER,
  RESET_USER,
  RECEIVE_ERROR,
  MENU_NEWINIT,
  MENU_EDITINIT,
  MENU_RECEIVE_GROUP,
  MENU_UPDATE_GROUP,
  MENU_DEL_GROUP,
  MENU_DEL_ITEMS,
  MENU_DEL_REMOVED_ITEMS,
  RECEIVE_STORE_HOURS,
  RECEIVE_SPECIAL_HOURS,
  DELETE_SPECIAL_HOURS,
  RECEIVE_TAX_MAP,
  RECEIVE_PMT_INFO,
  RECEIVE_PC_MAP,
  DELETE_PC_MAP,
  MENU_RECEIVE_ITEMS,
  MENU_UPDATE_ITEMS,
  MENU_UPDATE_ITEM_SIZES,
  MENU_UPDATE_ITEM_MODS,
  MENU_UPDATE_ITEM_MOD_ITEMS,
  RECEIVE_MENU_DELIPARTY,
  RECEIVE_MENU_TYPE,
  RECEIVE_MENU_CODE,
  RECEIVE_MENU_NAME,
  RECEIVE_MENU_DESC,
  RECEIVE_ORCK_CAT_MAP,
  DELETE_ORCK_CAT_MAP,
  RECEIVE_FLYT_UPLOAD_STORE_LIST, 
  RECEIVE_PARTNERS,
  UPDATE_PARTNERS,
  ADD_PARTNER,
  RECEIVE_PARTNER_STORE,
  UPDATE_PARTNER_STORES,
  DELETE_PARTNER_STORE
} from './action-types';
import { reqLogin } from '../api';
import storageUtil from '../util/storeUtil';

export const loginError = data => ({ type: LOGIN_ERROR, data });
export const receiveUser = user => ({ type: RECEIVE_USER, user });
export const resetUser = () => ({ type: RESET_USER});
export const receiveError = error => ({ type: RECEIVE_ERROR, error });
export const initNewMenu = () => ({ type: MENU_NEWINIT});
export const initEditMenu = existingMenu=>({type: MENU_EDITINIT, existingMenu});
export const receiveStoreHours = data => ({ type: RECEIVE_STORE_HOURS, data });
export const receiveSpecialHours = data => ({ type: RECEIVE_SPECIAL_HOURS, data });
export const delSpecialHours = data => ({ type: DELETE_SPECIAL_HOURS, data });
export const receiveTaxMap = data => ({ type: RECEIVE_TAX_MAP, data });
export const receivePmtInfo = data => ({ type: RECEIVE_PMT_INFO, data });
export const receivePcMap = data => ({ type: RECEIVE_PC_MAP, data });
export const delPcMap = data => ({ type: DELETE_PC_MAP, data });
export const receiveMenuGroup = data => ({ type: MENU_RECEIVE_GROUP, data });
export const updateMenuGroup = data => ({ type: MENU_UPDATE_GROUP, data });
export const delMenuGroup = data => ({ type: MENU_DEL_GROUP, data });
export const delMenuItems = data => ({ type: MENU_DEL_ITEMS, data });
export const delRemovedMenuItems = data => ({ type: MENU_DEL_REMOVED_ITEMS, data });
export const receiveItems = data => ({ type: MENU_RECEIVE_ITEMS, data });
export const updateMenuItems = data => ({ type: MENU_UPDATE_ITEMS, data });
export const updateMenuItemSizes = data => ({ type: MENU_UPDATE_ITEM_SIZES, data });
export const updateMenuItemMods = data => ({ type: MENU_UPDATE_ITEM_MODS, data });
export const updateMenuItemModItems = data => ({ type: MENU_UPDATE_ITEM_MOD_ITEMS, data });
export const receiveDeliveryparty = data => ({type: RECEIVE_MENU_DELIPARTY, data})
export const receiveMenuType = data => ({type: RECEIVE_MENU_TYPE, data})
export const receiveMenuCode = data => ({type: RECEIVE_MENU_CODE, data})
export const receiveMenuName = data => ({type: RECEIVE_MENU_NAME, data})
export const receiveMenuDesc = data => ({type: RECEIVE_MENU_DESC, data})
export const receiveOrckCatMap = data => ({type: RECEIVE_ORCK_CAT_MAP, data})
export const delOrckCatMap = data => ({type: DELETE_ORCK_CAT_MAP, data})
export const receiveFlytUploadStoreList = data => ({type: RECEIVE_FLYT_UPLOAD_STORE_LIST, data})
export const receivePartners = data => ({type: RECEIVE_PARTNERS, data})
export const updatePartners = data => ({type: UPDATE_PARTNERS, data})
export const addDeliPartner = data => ({type: ADD_PARTNER, data})
export const receivePartnerStore = data => ({type: RECEIVE_PARTNER_STORE, data})
export const updatePartnerStores = data => ({type: UPDATE_PARTNER_STORES, data})
export const delPartnerStore = data => ({type: DELETE_PARTNER_STORE, data})


export const logout = () => {
  return dispatch=>{
  storageUtil.removeUser();
  dispatch(resetUser())
  storageUtil.removeEnt();
  storageUtil.removeStore();
  };
};

export const login = value => {
  return async dispatch => {
    const re = await reqLogin(value);
    if (re.status === 200 && re.data) {
      storageUtil.saveUser(re.data);
      dispatch(receiveUser(re.data));
    }else if(re === 'Login Error'){
      dispatch(loginError(true))
    }
  };
};
